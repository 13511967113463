$c-main: #8d634b !default;
$c-contrast: #231f20 !default;

$c-dark: #000 !default;
$c-light: #eee !default;

$c-green: #4caf50 !default;
$c-red: #e53935 !default;
$c-white: white !default;
$c-black: black !default;
$c-mutted: #aaa !default;
$c-grey: #e5e5e5 !default;

$c-facebook: #3C5A99 !default;
$c-twitter: #1DA1F2 !default;
$c-googleplus: #DB4437 !default;
$c-instagram: #C13584 !default;
$c-youtube: #ff0416 !default;
$c-pinterest: #BD081C !default;
$c-whatsapp: #25D366 !default;
$c-linkedin: #0077B5 !default;

:root {
  --color-black: #000;
  --color-gray-1: #444;
  --color-gray-2: #666;
  --color-gray-3: #888;
  --color-gray-4: #CCC;
  --color-gray-5: #EEE;
  --color-white: #FFF;
  --color-green-1: #D6F4F0;
  --color-green-2: #4FC0B2;
  --color-green-3: #03A691;
  --color-green-4: #028474;
  --color-purple-1: #EBE5F5;
  --color-purple-2: #A780FF;
  --color-purple-3: #4300D2;
  --color-purple-4: #270570;
  --color-yellow-1: #FFF59D;
  --color-yellow-2: #FDD735;
  --color-yellow-3: #FFC000;
  --color-yellow-4: #F9A824;
  --color-orange-1: #FFE0C3;
  --color-orange-2: #FFBC67;
  --color-orange-3: #FA8E04;
  --color-orange-4: #D56202;
  --color-red-1: #FFE3E0;
  --color-red-2: #FB8478;
  --color-red-3: #FA503F;
  --color-red-4: #E12B19;
}
