@charset 'UTF-8';

//
// _____________________________________________________________________
// Base do tema


body {
  @include fontBody;
  font-size: 1rem;
  color: white;

  @media (min-width: 0) and (max-width:576px) {
    line-height: 1.5;
    // color: $c-dark;
  }

  @media (min-width:577px) and (max-width:767px) {
    line-height: 1.4;
  }

  @media (min-width:768px) and (max-width:992px) {
    line-height: 1.3;
  }

  @media (min-width:993px) {
    line-height: 1.2;
    font-size: 14px;
  }
}

#html .section:not(.-p) {
  @media (min-width: 0) and (max-width:576px) {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  @media (min-width:577px) and (max-width:992px) {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  @media (min-width:993px) {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
}

#html .section.-p {
  @media (min-width: 0) and (max-width:576px) {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  @media (min-width:577px) and (max-width:992px) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  @media (min-width:993px) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

//
// _____________________________________________________________________
// Titulos

.section-hero {

  &.-center {
    text-align: center;
  }

  &.-right {
    text-align: right;
  }

  &.-form {
    
    @media (min-width: 576px) {
      display: flex;
      align-items: center;

      .title {
        font-size: 2rem;
        margin-bottom: 0;
      }
    }

    .title {
      font-size: 1.5rem;
      margin-bottom: 2rem;
    }
  }

  @media (min-width: 0) and (max-width:992px) {
    margin-bottom: 1.5rem
  }

  @media (min-width:993px) {
    margin-bottom: 2rem
  }

  .title {
    @include title;
    text-transform: uppercase;
    margin-bottom: 0;
    font-weight: bold;

    &.-main {
      color: $c-main;
    }

    @media (min-width:0) and (max-width:576px) {
      font-size: 1.75rem;
    }

    @media (min-width:577px) and (max-width:992px) {
      font-size: 2rem;
    }

    @media (min-width:993px) {
      font-size: 2.75rem;
    }

    &.-line {
      position: relative;

      &::after {
        display: block;
        content: " ";
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        border: 0;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-color: inherit;
        opacity: 0.1;
      }
    }
  }

  .subtitle {
    @include subtitle;

    &.-main {
      color: $c-main;
    }

    @media (min-width: 0) and (max-width:576px) {
      font-size: 1rem;
    }

    @media (min-width:577px) and (max-width:992px) {
      font-size: 1.25rem;
    }

    @media (min-width:993px) {
      font-size: 18px;
    }
  }

  .title+.subtitle {
    margin-top: 0.5rem;
  }

  .form {

    @media (min-width: 576px) {
      margin-left: 3rem;
    }

    .form-control {
      background-color: black;
      // color: $c-main;
      font-weight: 700;
      box-shadow: none;
      min-width: 300px;
      letter-spacing: 2px;
    }
  }
}


//
// _____________________________________________________________________
// Active item 

$pages: "home",
"quem-somos",
"casas",
"lotes",
"imovel",
"aluguel",
"condominio";

@each $page in $pages {
  .page.-#{$page} {

    .main-navigation {

      .nav-item.-#{$page} {

        .nav-link {
          color: white;
          font-weight: bold;
        }
      }
    }

    .nav-item.-#{$page} {
      .nav-link {
        color: $c-main;
        text-shadow: 0 0 0;
      }
    }

    #mainFooter .nav-item.-#{$page} {
      .nav-link {
        color: white;
        text-shadow: 0 0 0;
      }
    }
  }
}


//
// _____________________________________________________________________
// Tema

$cores: ("main": $c-main,
  "primary": $c-main,
  "contrast": $c-contrast,
  "secondary": $c-contrast,
  "dark": $c-dark,
  "light": $c-light,

  "green": $c-green,
  "grey": $c-grey,
  "red": $c-red,
  "white": $c-white,
  "black": $c-black,
  "mutted": $c-mutted,

  "facebook": $c-facebook,
  "twitter": $c-twitter,
  "googleplus": $c-googleplus,
  "instagram": $c-instagram,
  "youtube": $c-youtube,
  "linkedin": $c-linkedin,
  "pinterest": $c-pinterest,
  "whatsapp": $c-whatsapp);

@function cor($key) {
  @return map-get($colors, $key);
}

html {

  @each $nome,
  $cor in $cores {
    .c-#{$nome} {
      color: $cor !important;
    }

    .bc-#{$nome} {
      background-color: $cor !important;
    }

    .btn-#{$nome} {
      background-color: $cor;
      color: white;

      &:hover {
        background-color: tint($cor, 10%);
        color: white;
      }

      &.active,
      &:active {
        background-color: shade($cor, 5%);
        color: white;
      }

      &:focus {
        box-shadow: 0 0 0 0.2rem rgba($cor, 0.25);
        color: white;
      }
    }

    .btn-border-#{$nome} {
      color: $cor;
      background-color: transparent;
      box-shadow: inset 0 0 0 2px $cor;

      &:hover,
      &:active {
        background-color: $cor;
        color: white;
      }

      &:focus {
        box-shadow: 0 0 0 0.2rem rgba($cor, 0.25);
      }
    }
  }

  .btn {
    @include btn;
    text-transform: uppercase;
  }
}

//
// _____________________________________________________________________
// Tema

.page {}

.dropdown-menu {
  @include sombra;
  border: none;
}

.box-card {
  @include box-card;
}

.box-card.-center {
  max-width: 320px;
  margin: auto;

  @media only screen and (min-width: 1024px) {
    max-width: 460px;
    padding: 2rem;
  }
}

.datetime {
  color: $c-mutted;
  font-weight: bold;
  @include fontBody;
}

.embed-responsive {

  &.-A4 {

    &::before {
      padding-top: 143%;
    }
  }
}

.form {

  .title {
    @include title;
    font-size: 1.25rem;
  }
}


//
// _____________________________________________________________________
// Responsivo


@media (max-width: 575.98px) {

  .col-md-4+.col-md-8,
  .col-md-8+.col-md-4,
  .col-md-6+.col-md-6 {
    margin-top: 3rem;

    &.form-group {
      margin-top: 0;
    }
  }
}

.mm-menu {
  background: $c-dark;
  color: $c-light;

  .mm-navbar {

    .mm-navbar__title {
      @include title;
    }

    a {
      color: inherit !important;
    }
  }

  .mm-navbars_bottom {

    .fa {
      font-size: 1.5rem;
    }
  }

  .mobile-navigation {
    font-weight: bold;

    .title {
      @include title;
      color: inherit !important;
      padding: 15px 20px;
      margin-top: 1rem;
      font-size: 1.5rem;
      background-color: lighten($c-dark, 2%);
    }
  }
}


//
// _____________________________________________________________________
// Lista

.theme-dl {

  &.-box {
    @include box-card;
  }

  dt {
    font-size: 12px;
    text-transform: uppercase;
    line-height: 1;
    color: $c-mutted;
    font-weight: normal;
    font-weight: 600;
  }

  dd {
    font-weight: bold;
  }

  dd+dt {
    margin-top: 1rem;
  }

  .title {
    font-size: 1.25rem;
    @include title;
  }
}


//
// _____________________________________________________________________
// Titulo da página

.page-title {
  padding: 0 0 4rem 0;
  @include bgimage;
  background-color: $c-dark;
  height: 300px;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  @media (min-width:577px) {
    height: 420px;
  }

  &.-overlay {
    position: relative;

    &::after {
      display: block;
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      background: linear-gradient(0deg, $c-dark 0%, rgba($c-dark, 0.8) 80%);

      // opacity: 0.85;
      // background-image: url(../img/noisy-texture.png);
    }

    .container {
      z-index: 2;
    }
  }

  .title {
    @include title;
    margin-bottom: 0;
    color: white;
    font-weight: bolder;
    text-transform: uppercase;

    &::after {
      content: " ";
      width: 4rem;
      height: 1px;
      display: block;
      margin: 1rem auto 0;
      border-bottom: 1px solid white;

      @media (min-width: 992px) {
        width: 7rem;
      }
    }

    &.-main {
      color: $c-main;
    }

    &.-dark {
      color: $c-dark;
    }

    @media (min-width:0) and (max-width:576px) {
      font-size: 2.5rem;
    }

    @media (min-width:577px) and (max-width:991.98px) {
      font-size: 3rem;
    }

    @media (min-width:992px) {
      font-size: 4rem;
      padding: 0 15%;
    }
  }

  .subtitle {
    @include subtitle;

    @media (min-width: 0) and (max-width:576px) {
      font-size: 1rem;
    }

    @media (min-width:577px) and (max-width:767px) {
      font-size: 1.25rem;
    }

    @media (min-width:768px) and (max-width:991.98px) {
      font-size: 1.5rem;
    }

    @media (min-width:992px) {
      font-size: 1rem;
    }
  }
}


//
// _____________________________________________________________________
// Lista

.preloader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $c-light;
  z-index: 99998748;
}

.preloader-ring {
  display: block;
  width: 64px;
  height: 64px;
}

.preloader-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid $c-main;
  border-color: $c-main transparent $c-main transparent;
  animation: preloader-animation 1s cubic-bezier(.160, .560, .170, .7) infinite;
}

@keyframes preloader-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


//
// _____________________________________________________________________
// Artigos

.article-body {

  .title {
    @include title;
    color: $c-main;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;

    @media (min-width: 576px) {
      font-size: 2rem;
    }
  }

  p {
    font-size: 1.25rem;
    margin-bottom: 1.25rem;
    color: $c-mutted;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    list-style: circle;
    padding-left: 19px;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;

    li {
      font-size: 1.1rem;
      margin-bottom: 0;

      &+li {
        margin-top: 0.5rem;
      }
    }
  }

  .embed-responsive {
    margin-bottom: 2rem;
    
    @media (min-width: 576px) {
      margin-top: 2rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  img {
    // border-radius: 0.25rem;
  }
}


//
// _____________________________________________________________________
// Slick Slider - arrows

#html .slick-slider {

  .slick-current {
    position: relative;
    z-index: 2;
  }

  .slick-counter {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    color: $c-main;
    font-weight: 500;
    line-height: 0.8;
    margin-top: 1rem;

    .current {
      font-size: 2.5rem;
      color: $c-dark;
    }

    .total {
      padding-left: 0.5rem;

      &::before {
        content: "/";
        display: inline-block;
      }
    }
  }

  .slide-control {
    box-shadow: none;
    
    @media (max-width: 991.98px) {
      zoom: 0.65;
    }

    &.-next {

      .fa::before {
        display: block;
        content: url(../img/icone-right.svg);
      }
    }

    &.-prev {

      .fa::before {
        display: block;
        content: url(../img/icone-left.svg);
      }
    }
  }
}
