@charset 'UTF-8';

// .container {
//   box-shadow: -1px 0px 0 0px cyan, 1px 0px 0 0px cyan;
// }


//
// _____________________________________________________________________
// Background no layout

body {
  background-color: $c-contrast;
}

.page {
  background-image: url(../img/bg-page.png);
  background-position: center top;
  background-repeat: no-repeat;
}

.page-wrapper {
  // background-image: url(../img/bg-line.png);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include fontTitle;
  font-weight: 200;
}

.container-fluid {
  padding-left: 2rem;
  padding-right: 2rem;
}


//
// _____________________________________________________________________
// Header

.main-header {
  z-index: 100;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  @media (min-width: 576px) {
    padding-top: 1rem;
    padding-bottom: 0;
    margin-bottom: 1rem;
  }

  &.is-fixed {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    @include animeStickyHeader;
    padding: 0.5rem 0;
    background-color: black;
    @include shadow12dp;

    .logo {
      max-height: 2rem !important;
      width: auto;
    }

    .main-navigation {
      white-space: nowrap;
      padding: 0;

      .nav-link {
        font-size: 14px;
      }
    }

    .header-contato {
      display: none;
    }
  }

  .header-main-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-logo-container {

    .logo {
      max-width: 200px;

      @media (min-width: 576px) {
        max-width: 256px;
      }
    }
  }

  .header-menu-container {
    color: #ccc;
  }

  .main-navigation {
    padding-bottom: 0;
    letter-spacing: 2px;

    .nav-item {}

    .current_page_item {
      color: white;
      text-shadow: 0 0 0;
    }

    .nav-link {
      text-transform: uppercase;
      font-weight: 600;

      &:hover {
        color: white;
        font-weight: bold;
      }
    }
  }

  .header-contato {
    text-align: right;
    padding-right: 1rem;

    .fab {
      font-size: 1rem;
      margin-left: 0.5rem;
    }
  }

  .nav-social {
    display: none;
    justify-content: flex-end;
    padding-right: 1rem;
  }
}

.page.-transparent-header {

  .main-header:not(.is-fixed) {
    background: transparent;
    position: absolute;
    width: 100%;
    padding: 1rem 0;

    @media (min-width: 576px) {
      padding: 1rem;
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 200%;
      pointer-events: none;
      content: " ";
      background-image: linear-gradient(to top, transparent, rgba(#271e19, 0.8));
      z-index: -1;
    }
  }
}

.nav-social {
  padding: 0;
  margin-top: 1rem;

  .nav-item+.nav-item {
    @media (min-width: 576px) {
      margin-left: 2rem;
      margin-top: 0;
    }
  }

  .nav-link {
    padding: 0;
    vertical-align: top;
    display: flex;
    align-items: center;

    a,
    span {
      font-size: 14px;
      margin-left: 0.5rem;
    }
  }
}


//
// _____________________________________________________________________
// Footer

.main-footer {
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: black;

  @media (min-width: 576px) {
    background-color: transparent;
    padding-top: 0;
  }

  .row {
    align-items: center;
  }

  .logo-footer {
    display: block;
    text-align: center;

    @media (min-width: 576px) {
      display: inline;
      text-align: inherit;
    }
  }

  .link-ilion {}

  .logo-ilion {
    opacity: 0.3;

    &:hover {
      opacity: 0.6;
    }
  }

  .nav-social {
    margin-bottom: 1rem;
    justify-content: center;

    @media (min-width: 576px) {
      justify-content: flex-start;
    }

    .nav-item+.nav-item {
      margin-left: 1rem;
    }

    .nav-item.-whatsapp {

      &:hover {
        color: $c-whatsapp;
      }
    }

    .nav-link {
      font-size: 1.5rem;
    }
  }

  .copyright {
    text-transform: uppercase;
    color: $c-mutted;
    text-align: center;
    margin-bottom: 2rem;

    @media (min-width: 576px) {
      margin-bottom: 0;
      text-align: left;
    }
  }
}


//
// _____________________________________________________________________
// Empreendimentos

.listagem-imoveis {

  .item-imovel {
    padding: 1rem;

    .imovel {}
  }

  .imovel {
    background-image: url(../img/logo-color.png);
    background-repeat: no-repeat;
    background-position: top 3rem center;
    background-size: auto 20%;
    background-color: #000;

    @include anima;
    @include shadow6dp;

    &:hover {
      @include shadow8dp;
      box-shadow: 0 0 0 2px $c-main;

      .img {
        opacity: 1;
      }
    }

    .content {
      display: flex;
      justify-content: flex-end;
      flex-flow: column nowrap;
      @include anima;
      padding: 1rem;
      background-image: linear-gradient(to bottom, transparent 50%, #000);

      @media (min-width: 1600px) {
        padding: 2rem;
      }

      .title {
        @include title;
        font-size: 1.5rem;
        margin-bottom: 0.5rem;

        @media (min-width: 576px) {
          font-size: 1.7rem;
        }
      }

      .info {
        display: flex;
        align-items: center;
        line-height: 1;
        font-size: 1rem;

        span+span {
          margin-left: 1rem;
        }

        .info-preco {
          color: $c-main;
          text-shadow: none !important;
          font-size: 1.25rem;
          font-weight: bold;

          @media (min-width: 576px) {
            font-size: 1rem;
            font-weight: normal;
          }

          @media (min-width: 1600px) {
            font-size: 1.5rem;
          }

          small {
            font-size: 70%;
          }
        }
      }
    }
  }
}

.form-filter {
  @media (max-width: 575.98px) {
    display: block !important;

    .form-group+.form-group {
      margin-top: 2rem;
    }
  }
}


//
// _____________________________________________________________________
// Pag.: Home

.page.-home {}

.home {}

.home-banner {
  @include bgimage;
  background-image: url(../img/thumbnail-banner.jpg);

  .embed-responsive {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(#332520, 0.5);
    max-height: 100vh;
  }

  .content {
    text-align: center;

    .title {
      @include fontTitle;
      font-weight: bold;
      font-size: 4.5rem;
      margin-bottom: 2rem;
      text-transform: uppercase;
      letter-spacing: 0.5rem;
      line-height: 1;
      text-shadow: 0 15px 5px rgba(0, 0, 0, 0.1),
        10px 20px 5px rgba(0, 0, 0, 0.05),
        -10px 20px 5px rgba(0, 0, 0, 0.05);
    }

    .subtitle {
      @include fontTitle;
      font-weight: 200;
      font-size: 1.5rem;
      line-height: 1;
      letter-spacing: 2px;
      text-shadow: 0 15px 5px rgba(0, 0, 0, 0.1),
        10px 20px 5px rgba(0, 0, 0, 0.05),
        -10px 20px 5px rgba(0, 0, 0, 0.05);
    }
  }
}


//
// _____________________________________________________________________
// Pag.: Quem somos

.page.-quem-somos {
  background-image: url(../img/bg-page-quem-somos.jpg);
  background-position: center center;
  background-size: cover;

  .page-wrapper {
    background-color: rgba($c-contrast, 0.8);
  }

  .nav-social {
    @media (min-width: 576px) {
      display: flex;
    }
  }
}

.quem-somos {}


//
// _____________________________________________________________________
// Pag.: Casas

.page.-casas {}

.casas {}


//
// _____________________________________________________________________
// Pag.: Lotes

.page.-lotes {}

.lotes {}


//
// _____________________________________________________________________
// Pag.: Aluguel

.page.-aluguel {}

.aluguel {}


//
// _____________________________________________________________________
// Pag.: Condomínio

.page.-condominio {

  .header-logo-container {

    a {
      display: flex;
      align-items: center;
    }

    .invisible {
      display: none;
      height: auto !important;
      width: auto !important;
      clip: inherit !important;
      overflow: visible !important;
      position: static !important;
      visibility: visible !important;
      @include title;
      font-size: 2.5rem;
      margin-left: 2rem;
    }
  }
}


//
// _____________________________________________________________________
// Imovel

.page.-imovel {}

.imovel {

  .article-body {

    .embed-responsive {
      background-color: black;
      background-image: url(../img/logo-color.png);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 30%;
    }
  }
}

.masthead-imovel {
  position: relative;

  .logo-container {
    margin-right: 3rem;

    .logo {
      max-width: 200px;

      @media (min-width: 576px) {
        max-width: 256px;
      }
    }
  }

  .masthead-title {
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    color: white;
    padding: 1rem 0;
    background-image: linear-gradient(to top, transparent, rgba(#000, 0.95) 85%);

    @media (min-width: 992px) {
      position: absolute;
      padding: 2rem 0 8rem 0;

      .container-fluid {
        display: flex;
        align-items: center;
      }

      .imovel-info {
        display: flex;
        align-items: baseline;
      }
    }

    .title {
      font-size: 1.5rem;
      margin-top: 3rem;
      margin-bottom: 0.5rem;
      white-space: nowrap;
      text-transform: uppercase;

      @media (min-width: 992px) {
        margin-right: 1rem;
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    .list-info-imovel {
      @media (min-width: 576px) {
        display: flex;
        align-items: center;
      }

      .info {
        // font-size: 1rem;
        white-space: nowrap;
        display: inline-block;

        &+.info {
          margin-left: 1rem;
        }

        &.-preco {
          // color: $c-main;
          display: block;
          color: #B1856D;
          font-size: 1.25rem;

          @media (min-width: 576px) {
            font-size: 1rem;
          }

          .val {
            font-weight: 900;
          }
        }

        &.-area {
          margin-left: 0;

          @media (min-width: 576px) {
            margin-left: 1rem;
          }
        }

        &.-quartos {}

        &.-area-lote {}

        .img {
          display: none;
          margin-right: 0.5rem;
          vertical-align: bottom;

          @media (min-width: 576px) {
            display: inline-block;
          }
        }
      }
    }
  }

  .banner-imovel {}

  .banner-thumbnail {}

  .slide-control {

    &.-prev {
      right: auto;
      left: 0;
    }

    &.-next {
      left: auto;
      right: 0;
    }
  }

  .slick-dots {
    position: absolute;
    bottom: 1rem;
  }

  .masthead-menu {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1.5rem;

    @media (min-width: 576px) {
      margin-right: 1.5rem;
      margin-top: 1.5rem;
      padding: 0;
    }

    @media (min-width: 992px) {
      margin-right: 2rem;
      margin-top: 2.5rem;
    }
  }
}

#html .btn-menu {
  color: white;
  padding: 0;

  &:hover {
    color: $c-main;
  }

  .fa {
    font-size: 2rem;
    line-height: 0.8;
  }
}

.masthead-imovel-thumbnails {
  padding: 0.5rem 1.5rem;
  display: none;

  @media (min-width: 576px) {
    display: block;
  }

  .thumbnails-imovel {
    margin: 0;
    flex-flow: row nowrap;
    width: 100%;
  }

  .item {
    margin: 0.15rem;
    // flex: 0 0 10%;
    // max-width: 128px;
    // min-width: 80px;

    @media (min-width: 992px) {
      margin: 0.35rem;
    }

    &.active img {
      box-shadow: 0 0 0 2px $c-main;
    }
  }
}

.nav-social.-imovel {
  margin-top: 3rem;
  display: block;
  text-transform: uppercase;
  font-size: 13px;

  @media (min-width: 576px) {
    padding: 0 2rem;
    display: flex;
    padding: 0;
  }

  .nav-item+.nav-item {
    margin-top: 1rem;

    @media (min-width: 576px) {
      margin-top: 0;
    }
  }

  .nav-link {
    color: white;
    letter-spacing: 2px;
    font-weight: 700;

    &.c-whatsapp {
      color: inherit;

      i {
        color: inherit;
      }
    }

    i {
      color: $c-main;
      font-size: 1.5rem;
      margin-right: 0.5rem;
    }
  }
}


//
// _____________________________________________________________________
// Filtro de Imoveis

#html .section-hero .form {

  .form-group {
    margin-bottom: 0;

    @media (min-width: 576px) {
      &.-flex {
        display: flex;
        align-items: center;

        label {
          margin-right: 1rem;
        }
      }
    }
  }

  .input-mostra-valor {
    box-shadow: none;
    color: $c-main;
    font-weight: bold;
    width: 100%;
    text-align: center;
    min-width: 300px;
    margin-bottom: 6px;
  }

  .noUi-target,
  .ui-slider-horizontal {
    background: transparent;
    border: 0 none;
    height: 4px;
    box-shadow: none;
    background: rgba(255, 255, 255, 0.2);
  }
  
  .noUi-connect,
  .ui-slider-horizontal .ui-widget-header {
    box-shadow: none;
    background: rgba(255, 255, 255, 0.2);
  }

  .noUi-handle,
  .ui-slider-horizontal .ui-slider-handle {
    top: 50%;
    transform: translateY(-50%);
    border: 0 none;
    background: $c-main;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 1.25rem;
    box-shadow: none;
  }

  .noUi-handle {

    &::after,
    &::before {
      display: none;
    }
  }
}

#html .video {
  background-color: $c-main;
  border-radius: 0;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 0 2px $c-main;

    .icone-play-video {
      transform: translate(-50%, -50%) scale(1.25);
    }
  }

  .icone-play-video {
    font-size: 3rem;
    text-align: center;
    background-color: transparent;
    color: $c-main;
    z-index: 10;
    position: absolute;
    top: 50%;
    left: 50%;
    color: white;
    transform: translate(-50%, -50%);
    @include anima;
    pointer-events: none;
  }

  iframe {
    z-index: 20;
  }

  .embed-responsive-item {
    border-radius: 0;
  }
}

#html .modal-open .modal {
  background-color: rgba(0, 0, 0, 0.75);
}

#html .modal-imovel {

  .modal-content {
    background-color: #444;
    border-radius: 0;
  }

  .close {
    color: #d7d7d7;
    text-shadow: none;
    position: absolute;
    right: 0;
    top: 0;
    margin: 1rem;
    line-height: 0.6;
  }

  .ajax-loader {
    display: none;
  }

  .wpcf7-validation-errors,
  .wpcf7-acceptance-missing {
    border-color: $c-main;
  }
}

//
// _____________________________________________________________________
// Ajustes

#html {

  .link-whatsapp {

    &:hover {
      color: $c-whatsapp !important;
    }
  }
}


//
// _____________________________________________________________________
// Titulo
