@charset 'UTF-8';
/*!
 * ILION
 *
 * @year 2019
 * @copyright Ilion
 *
 */
/*
* reset.css
* by Paulo Melo
*/
/**
* At-rules - Set the encoding of the style sheet to Unicode UTF-8
* Mozilla Developer Network [https://developer.mozilla.org/en-US/docs/Web/CSS/@charset]
*
* @charset "UTF-8";
*/
/**
* Basic UI - Apply a natural box layout model to all elements. [Firefox, WebKit, Opera and IE8+]
* Paul Irish [http://www.paulirish.com/2012/box-sizing-border-box-ftw/]
*/
html
{
    box-sizing: border-box;
}

html *
{
    box-sizing: inherit;
}

/**
* Basic Box Model - Correct 'block' display not defined in [IE 8+]
*/
article,
aside,
datagrid,
datalist,
details,
dialog,
figure,
footer,
header,
main,
menu,
nav,
section,
summary
{
    display: block;
}

audio,
canvas,
progress,
video
{
    display: inline-block;
}

abbr,
eventsource,
mark,
meter,
time,
output,
bb
{
    display: inline;
}

/* Basic Box Model - Remove default Margin. */
body,
blockquote,
figure,
form,
h1,
h2,
h3,
h4,
h5,
h6,
p
{
    margin: 0;
}

/* Basic Box Model - Remove default Margin and Padding. */
button,
code,
dd,
dl,
dt,
fieldset,
hr,
input,
legend,
menu,
ol,
pre,
select,
td,
textarea,
th,
ul
{
    margin: 0;
    padding: 0;
}

/* Remove default Border. */
a img,
abbr,
button,
fieldset,
hr,
iframe,
input,
select,
table,
textarea
{
    border: 0 none;
}

/* Alignments */
label,
button,
input,
select,
audio,
canvas,
iframe,
img,
svg,
video
{
    vertical-align: middle;
}

table
{
    border-spacing: 0;
    border-collapse: collapse;
}

@media (min-width: 0) and (max-width: 576px)
{
    :root
    {
        font: normal normal normal 13px/1.5 -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
    }
}

@media (min-width: 577px) and (max-width: 767px)
{
    :root
    {
        font: normal normal normal 14px/1.4 -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
    }
}

@media (min-width: 768px) and (max-width: 992px)
{
    :root
    {
        font: normal normal normal 15px/1.3 -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
    }
}

@media (min-width: 993px)
{
    :root
    {
        font: normal normal normal 100%/1.2 -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
    }
}

:root
{
    --color-black: #000;
    --color-gray-1: #444;
    --color-gray-2: #666;
    --color-gray-3: #888;
    --color-gray-4: #ccc;
    --color-gray-5: #eee;
    --color-white: #fff;
    --color-green-1: #d6f4f0;
    --color-green-2: #4fc0b2;
    --color-green-3: #03a691;
    --color-green-4: #028474;
    --color-purple-1: #ebe5f5;
    --color-purple-2: #a780ff;
    --color-purple-3: #4300d2;
    --color-purple-4: #270570;
    --color-yellow-1: #fff59d;
    --color-yellow-2: #fdd735;
    --color-yellow-3: #ffc000;
    --color-yellow-4: #f9a824;
    --color-orange-1: #ffe0c3;
    --color-orange-2: #ffbc67;
    --color-orange-3: #fa8e04;
    --color-orange-4: #d56202;
    --color-red-1: #ffe3e0;
    --color-red-2: #fb8478;
    --color-red-3: #fa503f;
    --color-red-4: #e12b19;
}

@keyframes animeDropdown
{
    from
    {
        transform: translateY(-1rem);

        opacity: 0;
    }
}

@keyframes animeStickyHeader
{
    from
    {
        transform: translateY(-4rem) translateZ(-500px);

        opacity: 0;
        box-shadow: 0 24px 38px 3px rgba(0, 0, 0, .07), 0 9px 46px 8px rgba(0, 0, 0, .06), 0 11px 15px -7px rgba(0, 0, 0, .1);
    }
}

/* Shadow 1dp */
/* Shadow 2dp */
/* Shadow 3dp */
/* Shadow 4dp */
/* Shadow 6dp */
/* Shadow 8dp */
/* Shadow 9dp */
/* Shadow 12dp */
/* Shadow 16dp */
/* Shadow 24dp */
/*
  base.css */
:root,
body
{
    overflow-x: hidden;
}

@media only screen and (max-device-width: 1024px)
{
    :root
    {
        -ms-overflow-style: -ms-autohiding-scrollbar;
           -moz-text-size-adjust: 100%;
            -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
    }
}

button,
input,
optgroup,
option,
select,
textarea
{
    font: normal normal normal 100%/normal -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;

    text-rendering: optimizespeed;
}

address,
b,
caption,
cite,
code,
dfn,
em,
h1,
h2,
h3,
h4,
h5,
h6,
i,
optgroup,
option,
strong,
th,
var
{
    font-weight: normal;
    font-style: normal;

    text-rendering: optimizespeed;
}

table
{
    font-size: inherit;

    width: 100%;
}

address,
p,
dl,
blockquote,
pre,
td,
th,
label,
ol,
ul,
textarea
{
    line-height: 1.61803;

    text-rendering: optimizespeed;
}

h1,
h2,
h3,
h4,
h5,
h6
{
    margin-bottom: 1rem;
}

p + h1,
p + h2,
p + h3,
p + h4,
p + h5,
p + h6
{
    margin-top: 2rem;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child
{
    margin-top: 0;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child
{
    margin-bottom: 0;
}

p
{
    margin-bottom: 1rem;
}

p:first-child
{
    margin-top: 0;
}

p:last-child
{
    margin-bottom: 0;
}

caption,
th
{
    text-align: left;
}

small
{
    font-size: 80%;
}

ul,
menu,
dir,
ol
{
    list-style: none;
}

html:focus,
a:hover,
a:active
{
    outline: 0;
}

:active,
:focus
{
    outline: 0 !important;
}

a
{
    text-decoration: none;

    color: inherit;
}

a:hover
{
    color: inherit;
}

.link:hover
{
    cursor: pointer;
    text-decoration: underline;
}

img,
table,
td,
blockquote,
code,
pre,
textarea,
input,
svg
{
    max-width: 100%;
    height: auto;
}

object,
embed
{
    position: relative;

    max-width: 100%;
    height: 100%;
}

embed,
img,
object,
svg
{
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;

    -webkit-user-drag: none;
}

dl,
dt,
dd
{
    vertical-align: top;
}

.lt-ie8 img
{
    -ms-interpolation-mode: bicubic;
}

abbr[title]
{
    border-bottom: 1px dotted;
}

[hidden]
{
    display: none;
}

[readonly],
[disabled]
{
    cursor: default;

    opacity: .75;
}

[disabled],
[readonly],
[unselectable]
{
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-user-drag: none;
    -webkit-touch-callout: none;
}

blockquote:last-child,
pre:last-child,
table:last-child
{
    margin-bottom: 0;
}

code,
pre,
samp
{
    white-space: pre-wrap;
}

code
{
    line-height: 1;
}

kbd
{
    padding: 0 .25rem;

    border: solid 1px;
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
}

abbr[title],
dfn[title]
{
    cursor: help;

    border-bottom: dotted 1px;
}

hr
{
    display: block;

    height: 1px;
    margin-top: 2rem;
    margin-bottom: 2rem;

    background-color: #ddd;
}

@media screen
{
    [hidden~=screen]
    {
        display: inherit;
    }
    [hidden~=screen]:not(:active):not(:focus):not(:target)
    {
        position: absolute !important;

        clip: rect(0 0 0 0) !important;
    }
}

/*
    typography.css */
body
{
    color: rgba(68, 68, 68, .8);

    text-rendering: optimizespeed;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 576px)
{
    h1
    {
        font-size: 1.55rem;
    }
    h2
    {
        font-size: 1.5rem;
    }
    h3
    {
        font-size: 1.25rem;
    }
    h4
    {
        font-size: 1.2rem;
    }
    h5
    {
        font-size: 1.1rem;
    }
    h6
    {
        font-size: 1.05rem;
    }
}

@media (min-width: 768px) and (max-width: 992px)
{
    h1
    {
        font-size: 2.1rem;
    }
    h2
    {
        font-size: 2rem;
    }
    h3
    {
        font-size: 1.6rem;
    }
    h4
    {
        font-size: 1.5rem;
    }
    h5
    {
        font-size: 1.25rem;
    }
    h6
    {
        font-size: 1.15rem;
    }
}

@media (min-width: 993px)
{
    h1
    {
        font-size: 2.75rem;
    }
    h2
    {
        font-size: 2.5rem;
    }
    h3
    {
        font-size: 1.75rem;
    }
    h4
    {
        font-size: 1.5rem;
    }
    h5
    {
        font-size: 1.15rem;
    }
    h6
    {
        font-size: 1rem;
    }
}

h1,
h2
{
    font-weight: 200;
}

h3,
h4
{
    font-weight: 600;
}

h5,
h6
{
    font-weight: 700;
}

dfn,
em,
i
{
    font-style: italic;
}

b,
label,
option,
strong
{
    font-weight: 600;
}

.text-thin
{
    font-weight: 200;
}

.text-regular
{
    font-weight: 400;
}

.text-medium
{
    font-weight: 600;
}

.text-bold
{
    font-weight: 700;
}

.text-black
{
    font-weight: 800;
}

@font-face
{
    font-family: 'Lato';
    font-weight: 300;
    font-style: normal;

    src: local('Lato Light'), local('Lato-Light'), url(https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh7USSwiPGQ3q5d0.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face
{
    font-family: 'Lato';
    font-weight: 400;
    font-style: normal;

    src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wXiWtFCc.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face
{
    font-family: 'Lato';
    font-weight: 700;
    font-style: normal;

    src: local('Lato Bold'), local('Lato-Bold'), url(https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPGQ3q5d0.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face
{
    font-family: 'Lato';
    font-weight: 900;
    font-style: normal;

    src: local('Lato Black'), local('Lato-Black'), url(https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh50XSwiPGQ3q5d0.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face
{
    font-family: 'PT Serif';
    font-weight: 400;
    font-style: italic;

    src: local('PT Serif Italic'), local('PTSerif-Italic'), url(https://fonts.gstatic.com/s/ptserif/v11/EJRTQgYoZZY2vCFuvAFT_r21cgT9rcs.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face
{
    font-family: 'PT Serif';
    font-weight: 400;
    font-style: normal;

    src: local('PT Serif'), local('PTSerif-Regular'), url(https://fonts.gstatic.com/s/ptserif/v11/EJRVQgYoZZY2vCFuvAFWzr-_dSb_.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face
{
    font-family: 'PT Serif';
    font-weight: 700;
    font-style: normal;

    src: local('PT Serif Bold'), local('PTSerif-Bold'), url(https://fonts.gstatic.com/s/ptserif/v11/EJRSQgYoZZY2vCFuvAnt66qSVyvVp8NA.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.-ptserif,
._ff-ptserif
{
    font-family: 'PT Serif', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
    font-weight: 400;
}

.-lato,
._ff-lato
{
    font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
}

::selection
{
    color: white;
    background: #8d634b;
}

a,
img
{
    transition: all .4s cubic-bezier(.16, .56, .17, .999);
}

a:hover
{
    text-decoration: none;

    color: inherit;
    text-shadow: 0 0 0;
}

a.link
{
    text-decoration: underline;
}

address
{
    margin-bottom: 0;
}

/* Inputs */
select,
textarea,
[type='text'],
[type='password'],
[type='datetime'],
[type='datetime-local'],
[type='date'],
[type='month'],
[type='time'],
[type='week'],
[type='number'],
[type='email'],
[type='url'],
[type='search'],
[type='tel']
{
    font-size: 14px;
    line-height: 2rem;

    height: 2rem;
    padding: 0 1rem;

    transition: all .4s cubic-bezier(.16, .56, .17, .999);

    border: 0 none;
    border-radius: 2rem;
    background: transparent;
    box-shadow: inset 0 0 0 2px #8d634b;
}

select.-small,
textarea.-small,
[type='text'].-small,
[type='password'].-small,
[type='datetime'].-small,
[type='datetime-local'].-small,
[type='date'].-small,
[type='month'].-small,
[type='time'].-small,
[type='week'].-small,
[type='number'].-small,
[type='email'].-small,
[type='url'].-small,
[type='search'].-small,
[type='tel'].-small
{
    line-height: 1.5rem;

    height: 1.5rem;
    padding: 0 .75rem;

    border-radius: 1.5rem;
}

select.-medium,
textarea.-medium,
[type='text'].-medium,
[type='password'].-medium,
[type='datetime'].-medium,
[type='datetime-local'].-medium,
[type='date'].-medium,
[type='month'].-medium,
[type='time'].-medium,
[type='week'].-medium,
[type='number'].-medium,
[type='email'].-medium,
[type='url'].-medium,
[type='search'].-medium,
[type='tel'].-medium
{
    line-height: 2.5rem;

    height: 2.5rem;
    padding: 0 1.5rem;

    border-radius: 2.5rem;
}

select.-large,
textarea.-large,
[type='text'].-large,
[type='password'].-large,
[type='datetime'].-large,
[type='datetime-local'].-large,
[type='date'].-large,
[type='month'].-large,
[type='time'].-large,
[type='week'].-large,
[type='number'].-large,
[type='email'].-large,
[type='url'].-large,
[type='search'].-large,
[type='tel'].-large
{
    line-height: 3rem;

    height: 3rem;
    padding: 0 2rem;

    border-radius: 3rem;
}

@media only screen and (min-width: 1024px)
{
    select.-small,
    textarea.-small,
    [type='text'].-small,
    [type='password'].-small,
    [type='datetime'].-small,
    [type='datetime-local'].-small,
    [type='date'].-small,
    [type='month'].-small,
    [type='time'].-small,
    [type='week'].-small,
    [type='number'].-small,
    [type='email'].-small,
    [type='url'].-small,
    [type='search'].-small,
    [type='tel'].-small
    {
        font-size: .75rem;
    }
    select.-medium,
    textarea.-medium,
    [type='text'].-medium,
    [type='password'].-medium,
    [type='datetime'].-medium,
    [type='datetime-local'].-medium,
    [type='date'].-medium,
    [type='month'].-medium,
    [type='time'].-medium,
    [type='week'].-medium,
    [type='number'].-medium,
    [type='email'].-medium,
    [type='url'].-medium,
    [type='search'].-medium,
    [type='tel'].-medium
    {
        font-size: 14.5px;
    }
    select.-large,
    textarea.-large,
    [type='text'].-large,
    [type='password'].-large,
    [type='datetime'].-large,
    [type='datetime-local'].-large,
    [type='date'].-large,
    [type='month'].-large,
    [type='time'].-large,
    [type='week'].-large,
    [type='number'].-large,
    [type='email'].-large,
    [type='url'].-large,
    [type='search'].-large,
    [type='tel'].-large
    {
        font-size: 1.1rem;
    }
}

select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
[type='text']::-webkit-input-placeholder,
[type='password']::-webkit-input-placeholder,
[type='datetime']::-webkit-input-placeholder,
[type='datetime-local']::-webkit-input-placeholder,
[type='date']::-webkit-input-placeholder,
[type='month']::-webkit-input-placeholder,
[type='time']::-webkit-input-placeholder,
[type='week']::-webkit-input-placeholder,
[type='number']::-webkit-input-placeholder,
[type='email']::-webkit-input-placeholder,
[type='url']::-webkit-input-placeholder,
[type='search']::-webkit-input-placeholder,
[type='tel']::-webkit-input-placeholder
{
    font-weight: 500;

    transition: all .4s cubic-bezier(.16, .56, .17, .999);

    opacity: .4;
    color: inherit;
}

select:-moz-placeholder,
textarea:-moz-placeholder,
[type='text']:-moz-placeholder,
[type='password']:-moz-placeholder,
[type='datetime']:-moz-placeholder,
[type='datetime-local']:-moz-placeholder,
[type='date']:-moz-placeholder,
[type='month']:-moz-placeholder,
[type='time']:-moz-placeholder,
[type='week']:-moz-placeholder,
[type='number']:-moz-placeholder,
[type='email']:-moz-placeholder,
[type='url']:-moz-placeholder,
[type='search']:-moz-placeholder,
[type='tel']:-moz-placeholder
{
    font-weight: 500;

    transition: all .4s cubic-bezier(.16, .56, .17, .999);

    opacity: .4;
    color: inherit;
}

select::-moz-placeholder,
textarea::-moz-placeholder,
[type='text']::-moz-placeholder,
[type='password']::-moz-placeholder,
[type='datetime']::-moz-placeholder,
[type='datetime-local']::-moz-placeholder,
[type='date']::-moz-placeholder,
[type='month']::-moz-placeholder,
[type='time']::-moz-placeholder,
[type='week']::-moz-placeholder,
[type='number']::-moz-placeholder,
[type='email']::-moz-placeholder,
[type='url']::-moz-placeholder,
[type='search']::-moz-placeholder,
[type='tel']::-moz-placeholder
{
    font-weight: 500;

    transition: all .4s cubic-bezier(.16, .56, .17, .999);

    opacity: .4;
    color: inherit;
}

select:-ms-input-placeholder,
textarea:-ms-input-placeholder,
[type='text']:-ms-input-placeholder,
[type='password']:-ms-input-placeholder,
[type='datetime']:-ms-input-placeholder,
[type='datetime-local']:-ms-input-placeholder,
[type='date']:-ms-input-placeholder,
[type='month']:-ms-input-placeholder,
[type='time']:-ms-input-placeholder,
[type='week']:-ms-input-placeholder,
[type='number']:-ms-input-placeholder,
[type='email']:-ms-input-placeholder,
[type='url']:-ms-input-placeholder,
[type='search']:-ms-input-placeholder,
[type='tel']:-ms-input-placeholder
{
    font-weight: 500;

    transition: all .4s cubic-bezier(.16, .56, .17, .999);

    opacity: .4;
    color: inherit;
}

select:hover,
textarea:hover,
[type='text']:hover,
[type='password']:hover,
[type='datetime']:hover,
[type='datetime-local']:hover,
[type='date']:hover,
[type='month']:hover,
[type='time']:hover,
[type='week']:hover,
[type='number']:hover,
[type='email']:hover,
[type='url']:hover,
[type='search']:hover,
[type='tel']:hover
{
    box-shadow: inset 0 0 0 2px #8d634b;
}

select:hover::-webkit-input-placeholder,
textarea:hover::-webkit-input-placeholder,
[type='text']:hover::-webkit-input-placeholder,
[type='password']:hover::-webkit-input-placeholder,
[type='datetime']:hover::-webkit-input-placeholder,
[type='datetime-local']:hover::-webkit-input-placeholder,
[type='date']:hover::-webkit-input-placeholder,
[type='month']:hover::-webkit-input-placeholder,
[type='time']:hover::-webkit-input-placeholder,
[type='week']:hover::-webkit-input-placeholder,
[type='number']:hover::-webkit-input-placeholder,
[type='email']:hover::-webkit-input-placeholder,
[type='url']:hover::-webkit-input-placeholder,
[type='search']:hover::-webkit-input-placeholder,
[type='tel']:hover::-webkit-input-placeholder
{
    opacity: .5;
    text-shadow: 0 0 0;
}

select:hover:-moz-placeholder,
textarea:hover:-moz-placeholder,
[type='text']:hover:-moz-placeholder,
[type='password']:hover:-moz-placeholder,
[type='datetime']:hover:-moz-placeholder,
[type='datetime-local']:hover:-moz-placeholder,
[type='date']:hover:-moz-placeholder,
[type='month']:hover:-moz-placeholder,
[type='time']:hover:-moz-placeholder,
[type='week']:hover:-moz-placeholder,
[type='number']:hover:-moz-placeholder,
[type='email']:hover:-moz-placeholder,
[type='url']:hover:-moz-placeholder,
[type='search']:hover:-moz-placeholder,
[type='tel']:hover:-moz-placeholder
{
    opacity: .5;
    text-shadow: 0 0 0;
}

select:hover::-moz-placeholder,
textarea:hover::-moz-placeholder,
[type='text']:hover::-moz-placeholder,
[type='password']:hover::-moz-placeholder,
[type='datetime']:hover::-moz-placeholder,
[type='datetime-local']:hover::-moz-placeholder,
[type='date']:hover::-moz-placeholder,
[type='month']:hover::-moz-placeholder,
[type='time']:hover::-moz-placeholder,
[type='week']:hover::-moz-placeholder,
[type='number']:hover::-moz-placeholder,
[type='email']:hover::-moz-placeholder,
[type='url']:hover::-moz-placeholder,
[type='search']:hover::-moz-placeholder,
[type='tel']:hover::-moz-placeholder
{
    opacity: .5;
    text-shadow: 0 0 0;
}

select:hover:-ms-input-placeholder,
textarea:hover:-ms-input-placeholder,
[type='text']:hover:-ms-input-placeholder,
[type='password']:hover:-ms-input-placeholder,
[type='datetime']:hover:-ms-input-placeholder,
[type='datetime-local']:hover:-ms-input-placeholder,
[type='date']:hover:-ms-input-placeholder,
[type='month']:hover:-ms-input-placeholder,
[type='time']:hover:-ms-input-placeholder,
[type='week']:hover:-ms-input-placeholder,
[type='number']:hover:-ms-input-placeholder,
[type='email']:hover:-ms-input-placeholder,
[type='url']:hover:-ms-input-placeholder,
[type='search']:hover:-ms-input-placeholder,
[type='tel']:hover:-ms-input-placeholder
{
    opacity: .5;
    text-shadow: 0 0 0;
}

select:focus,
textarea:focus,
[type='text']:focus,
[type='password']:focus,
[type='datetime']:focus,
[type='datetime-local']:focus,
[type='date']:focus,
[type='month']:focus,
[type='time']:focus,
[type='week']:focus,
[type='number']:focus,
[type='email']:focus,
[type='url']:focus,
[type='search']:focus,
[type='tel']:focus
{
    color: inherit;
    background: transparent;
    box-shadow: inset 0 0 0 2px #8d634b;
}

select:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder,
[type='text']:focus::-webkit-input-placeholder,
[type='password']:focus::-webkit-input-placeholder,
[type='datetime']:focus::-webkit-input-placeholder,
[type='datetime-local']:focus::-webkit-input-placeholder,
[type='date']:focus::-webkit-input-placeholder,
[type='month']:focus::-webkit-input-placeholder,
[type='time']:focus::-webkit-input-placeholder,
[type='week']:focus::-webkit-input-placeholder,
[type='number']:focus::-webkit-input-placeholder,
[type='email']:focus::-webkit-input-placeholder,
[type='url']:focus::-webkit-input-placeholder,
[type='search']:focus::-webkit-input-placeholder,
[type='tel']:focus::-webkit-input-placeholder
{
    transition-delay: .25s;

    opacity: .25;
}

select:focus:-moz-placeholder,
textarea:focus:-moz-placeholder,
[type='text']:focus:-moz-placeholder,
[type='password']:focus:-moz-placeholder,
[type='datetime']:focus:-moz-placeholder,
[type='datetime-local']:focus:-moz-placeholder,
[type='date']:focus:-moz-placeholder,
[type='month']:focus:-moz-placeholder,
[type='time']:focus:-moz-placeholder,
[type='week']:focus:-moz-placeholder,
[type='number']:focus:-moz-placeholder,
[type='email']:focus:-moz-placeholder,
[type='url']:focus:-moz-placeholder,
[type='search']:focus:-moz-placeholder,
[type='tel']:focus:-moz-placeholder
{
    transition-delay: .25s;

    opacity: .25;
}

select:focus::-moz-placeholder,
textarea:focus::-moz-placeholder,
[type='text']:focus::-moz-placeholder,
[type='password']:focus::-moz-placeholder,
[type='datetime']:focus::-moz-placeholder,
[type='datetime-local']:focus::-moz-placeholder,
[type='date']:focus::-moz-placeholder,
[type='month']:focus::-moz-placeholder,
[type='time']:focus::-moz-placeholder,
[type='week']:focus::-moz-placeholder,
[type='number']:focus::-moz-placeholder,
[type='email']:focus::-moz-placeholder,
[type='url']:focus::-moz-placeholder,
[type='search']:focus::-moz-placeholder,
[type='tel']:focus::-moz-placeholder
{
    transition-delay: .25s;

    opacity: .25;
}

select:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder,
[type='text']:focus:-ms-input-placeholder,
[type='password']:focus:-ms-input-placeholder,
[type='datetime']:focus:-ms-input-placeholder,
[type='datetime-local']:focus:-ms-input-placeholder,
[type='date']:focus:-ms-input-placeholder,
[type='month']:focus:-ms-input-placeholder,
[type='time']:focus:-ms-input-placeholder,
[type='week']:focus:-ms-input-placeholder,
[type='number']:focus:-ms-input-placeholder,
[type='email']:focus:-ms-input-placeholder,
[type='url']:focus:-ms-input-placeholder,
[type='search']:focus:-ms-input-placeholder,
[type='tel']:focus:-ms-input-placeholder
{
    transition-delay: .25s;

    opacity: .25;
}

.form-control
{
    font-size: 14px;
    line-height: 2rem;

    height: 2rem;
    padding: 0 1rem;

    transition: all .4s cubic-bezier(.16, .56, .17, .999);

    border: 0 none;
    border-radius: 2rem;
    background: transparent;
    box-shadow: inset 0 0 0 2px #8d634b;
}

.form-control.-small
{
    line-height: 1.5rem;

    height: 1.5rem;
    padding: 0 .75rem;

    border-radius: 1.5rem;
}

.form-control.-medium
{
    line-height: 2.5rem;

    height: 2.5rem;
    padding: 0 1.5rem;

    border-radius: 2.5rem;
}

.form-control.-large
{
    line-height: 3rem;

    height: 3rem;
    padding: 0 2rem;

    border-radius: 3rem;
}

@media only screen and (min-width: 1024px)
{
    .form-control.-small
    {
        font-size: .75rem;
    }
    .form-control.-medium
    {
        font-size: 14.5px;
    }
    .form-control.-large
    {
        font-size: 1.1rem;
    }
}

.form-control::-webkit-input-placeholder
{
    font-weight: 500;

    transition: all .4s cubic-bezier(.16, .56, .17, .999);

    opacity: .4;
    color: inherit;
}

.form-control:-moz-placeholder
{
    font-weight: 500;

    transition: all .4s cubic-bezier(.16, .56, .17, .999);

    opacity: .4;
    color: inherit;
}

.form-control::-moz-placeholder
{
    font-weight: 500;

    transition: all .4s cubic-bezier(.16, .56, .17, .999);

    opacity: .4;
    color: inherit;
}

.form-control:-ms-input-placeholder
{
    font-weight: 500;

    transition: all .4s cubic-bezier(.16, .56, .17, .999);

    opacity: .4;
    color: inherit;
}

.form-control:hover
{
    box-shadow: inset 0 0 0 2px #8d634b;
}

.form-control:hover::-webkit-input-placeholder
{
    opacity: .5;
    text-shadow: 0 0 0;
}

.form-control:hover:-moz-placeholder
{
    opacity: .5;
    text-shadow: 0 0 0;
}

.form-control:hover::-moz-placeholder
{
    opacity: .5;
    text-shadow: 0 0 0;
}

.form-control:hover:-ms-input-placeholder
{
    opacity: .5;
    text-shadow: 0 0 0;
}

.form-control:focus
{
    color: inherit;
    background: transparent;
    box-shadow: inset 0 0 0 2px #8d634b;
}

.form-control:focus::-webkit-input-placeholder
{
    transition-delay: .25s;

    opacity: .25;
}

.form-control:focus:-moz-placeholder
{
    transition-delay: .25s;

    opacity: .25;
}

.form-control:focus::-moz-placeholder
{
    transition-delay: .25s;

    opacity: .25;
}

.form-control:focus:-ms-input-placeholder
{
    transition-delay: .25s;

    opacity: .25;
}

input[type='checkbox'],
input[type='radio']
{
    box-shadow: none !important;
}

textarea
{
    line-height: 1.61803;

    height: auto;
    min-height: 80px;
}

/* select */
.styled-select
{
    position: relative;

    display: inline-block;

    width: 100%;
    height: auto;
    padding: 0 !important;

    cursor: pointer;
    white-space: nowrap;

    border: 0 none !important;
    box-shadow: none !important;
}

.styled-select select
{
    display: block;
    overflow: hidden;

    width: 100%;
    padding: 0 2rem 0 1rem;

    cursor: pointer;
    white-space: nowrap;
        text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;

     -o-text-overflow: ellipsis;
    -webkit-appearance: none;
       -moz-appearance: none;
}

.styled-select select option[selected]
{
    cursor: default;

    color: black;
    background-color: #f3f3a0;
    text-shadow: 0 0 0;
}

.styled-select.-small::after
{
    font-size: 1.25rem;
}

.styled-select.-medium::after
{
    font-size: 1.5rem;

    margin-right: 1rem;
}

.styled-select.-large::after
{
    font-size: 2rem;

    margin-right: 1.5rem;
}

.styled-select::after
{
    font: normal normal 900 1.5rem/1rem 'Font Awesome 5 Free';

    position: absolute;
    z-index: 2;
    top: 50%;
    right: 0;

    margin-right: .5rem;

    content: '\f107';
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
    transition: all .4s cubic-bezier(.16, .56, .17, .999);
    transform: translateX(0) translateY(-50%);
    pointer-events: none;

    opacity: .8;
    color: #8d634b;

    text-rendering: optimizeSpeed;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-user-drag: none;
}

.text-underline
{
    text-decoration: underline;
}

.badge
{
    transition: all .24s cubic-bezier(0, 0, .58, 1);
    transform: translate3d(0, 0, 0);

    border-radius: 6px;
}

.bc-instagram
{
    background-image: radial-gradient(circle farthest-corner at 32% 106%, #ffe17d 0, #ffcd69 10%, #fa9137 28%, #eb4141 42%, transparent 82%), linear-gradient(135deg, #234bd7 12%, #c33cbe 58%) !important;
}

.fa-whatsapp:hover
{
    color: #25d366 !important;
}

.fa-instagram:hover
{
    color: #c13584 !important;
}

.fa-facebook-square:hover,
.fa-facebook:hover
{
    color: #4267b2 !important;
}

.fa-twitter-square:hover,
.fa-twitter:hover
{
    color: #03b8ec !important;
}

.fa-linkedin:hover
{
    color: #0284ba !important;
}

.fa-googleplus:hover
{
    color: #ee5352 !important;
}

.fa-youtube:hover,
.fa-youtube-play:hover
{
    color: #ff0416 !important;
}

.fa-pinterest:hover
{
    color: #bd081c !important;
}

/*
 * Micro clearfix
 * Copyright © Nicolas Gallagher
 * Source: [http://nicolasgallagher.com/micro-clearfix-hack/]
 */
.cf::before,
.cf::after,
.clearfix::before,
.clearfix::after
{
    display: table;

    content: ' ';
}

.cf::after,
.clearfix::after
{
    clear: both;
}

.main-content,
.main-header,
.main-footer
{
    clear: both;
}

.embed-responsive.-anime img
{
    transition: all .4s cubic-bezier(0, 0, .58, 1);
    transform: scale(1);
    transform: translate3d(0, 0, 0);
}

.embed-responsive.-anime:hover img
{
    transform: scale(1.2);
}

.bg-image
{
    overflow: hidden;

    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-size: cover;
}

.bg-image.-fixed
{
    background-attachment: fixed;
    background-position-y: bottom;
}

.line-clamp,
.line-clamp-2,
.line-clamp-3,
.line-clamp-4
{
    display: -webkit-box;
    overflow: hidden;

        text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;

    -webkit-box-orient: vertical;
     -o-text-overflow: ellipsis;
    -webkit-line-clamp: 1;
}

.line-clamp-2
{
    -webkit-line-clamp: 2;
}

.line-clamp-3
{
    -webkit-line-clamp: 3;
}

.line-clamp-4
{
    -webkit-line-clamp: 4;
}

/* Techniques that make content inaccessible (invisible to all users).
* [https://developer.yahoo.com/blogs/ydn/clip-hidden-content-better-accessibility-53456.html]*/
.invisible,
.visually-hidden
{
    position: absolute !important;

    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);

    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;

    border: 0 !important;
}

:root:hover .invisible *,
:root:hover .visually-hidden *
{
    display: none !important;
}

.truncate,
.ellipsis
{
    overflow: hidden;

    white-space: nowrap;
        text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;

     -o-text-overflow: ellipsis;
}

.vertical-align
{
    position: relative;
    top: 50%;

    transform: translateY(-50%);
}

@-webkit-keyframes fadeIn
{
    0%
    {
        opacity: 0;
    }
}

@keyframes fadeIn
{
    0%
    {
        opacity: 0;

        -webkit-filter: alpha opacity=0;
           -moz-filter: alpha opacity=0;
                filter: alpha opacity=0;
    }
}

.fade-in
{
    -webkit-animation: fadeIn 1s normal none;
            animation: fadeIn 1s normal none;
    -webkit-animation-timing-function: cubic-bezier(.77, 0, .175, 1);
            animation-timing-function: cubic-bezier(.77, 0, .175, 1);
}

.fade-in.-xs
{
    -webkit-animation-duration: 500ms;
            animation-duration: 500ms;
}

.fade-in.-s
{
    -webkit-animation-duration: 1500ms;
            animation-duration: 1500ms;
}

.fade-in.-m
{
    -webkit-animation-duration: 2000ms;
            animation-duration: 2000ms;
}

.fade-in.-l
{
    -webkit-animation-duration: 2500ms;
            animation-duration: 2500ms;
}

.fade-in.-xl
{
    -webkit-animation-duration: 3000ms;
            animation-duration: 3000ms;
}

.fade-in.-xxl
{
    -webkit-animation-duration: 3500ms;
            animation-duration: 3500ms;
}

.cover
{
    position: relative;
    z-index: 1;

    display: block;
    overflow: hidden;

    width: 100%;

    text-align: center;
}

.media .cover
{
    max-width: 4rem;

    -webkit-box-flex: 1;
    -ms-flex: 1 0 4rem;
        flex: 1 0 4rem;
}

.list-media .media .cover
{
    max-width: 25%;

    -webkit-box-flex: 1;
    -ms-flex: 1 0 25%;
        flex: 1 0 25%;
}

.cover::before
{
    display: block;

    padding-top: 100%;

    content: '';
}

.cover img
{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;

    width: auto;
    max-width: none;
    height: 100%;

    border: 0;
}

.cover::after
{
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: block;

    content: '';

    opacity: 0;
    background-image: -webkit-gradient(linear, right top, left top, from(#434c63), to(#bc9b72));
    background-image: -webkit-linear-gradient(right, #434c63, #bc9b72);
    background-image:      -o-linear-gradient(right, #434c63, #bc9b72);
    background-image:         linear-gradient(to left, #434c63, #bc9b72);
}

.cover img,
.cover::after
{
    -webkit-transition: all .24s cubic-bezier(0, 0, .58, 1);
         -o-transition: all .24s cubic-bezier(0, 0, .58, 1);
            transition: all .24s cubic-bezier(0, 0, .58, 1);
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

a.cover::after
{
    font-family: 'fonticon';
    font-size: 2rem;
    line-height: 1;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
        flex-direction: column;

    content: '\e92d';
    -webkit-transform: scale(.95);
        -ms-transform: scale(.95);
            transform: scale(.95);

    color: white;

    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.cover:hover::after
{
    font-size: 2.25rem;

    -webkit-transform: scale(1);
        -ms-transform: scale(1);
            transform: scale(1);

    opacity: .85;
}

.cover:hover img
{
    -webkit-transition: all 2s cubic-bezier(0, 0, .58, 1);
         -o-transition: all 2s cubic-bezier(0, 0, .58, 1);
            transition: all 2s cubic-bezier(0, 0, .58, 1);
    -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
            transform: scale(1.1);
}

.cover img,
.cover::after
{
    -webkit-transition: all .24s cubic-bezier(0, 0, .58, 1);
         -o-transition: all .24s cubic-bezier(0, 0, .58, 1);
            transition: all .24s cubic-bezier(0, 0, .58, 1);
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.embed-content
{
    position: absolute;
    z-index: auto;
    top: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;
}

.embed-content.-caption
{
    z-index: 20;
}

.embed-content.-top
{
    top: 0;
    bottom: auto;

    height: auto;
    padding: .5rem;
}

.embed-content.-bottom
{
    top: auto;
    bottom: 0;

    height: auto;
    padding: .5rem;
}

.embed-content.-middle
{
    top: 50%;
    bottom: auto;

    height: auto;
    padding: .5rem;

    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
}

/* slick slider */
.carousel,
.slick-slider
{
    position: relative;
    z-index: auto;

    clear: both;
}

.slick-slider .slide-control
{
    font-size: 4rem;
    line-height: 3rem;

    position: absolute;
    z-index: 2;
    top: 50%;

    width: 4rem;
    height: 4rem;
    padding: 0;

    transition: all .4s cubic-bezier(.16, .56, .17, .999);
    transform: translateY(-50%);

    opacity: 1;
    color: #333;
    border: 0 none;
    border-radius: 0;
}

.slick-slider .slide-control .fa
{
    position: relative;

    transition: all .4s cubic-bezier(.16, .56, .17, .999);

    color: #8d634b;
}

.slick-slider .slide-control.-prev
{
    left: 0;
}

.slick-slider .slide-control.-next
{
    right: 0;
}

@media (min-width: 576px)
{
    .slick-slider .slide-control.-prev
    {
        right: 100%;
        left: auto;
    }
    .slick-slider .slide-control.-next
    {
        right: auto;
        left: 100%;
    }
}

.slick-slider .slide-control.-prev:hover .fa
{
    transform: translateX(-.5rem);
}

.slick-slider .slide-control.-next:hover .fa
{
    transform: translateX(.5rem);
}

.slick-slider .slick-dots
{
    position: relative;
}

.slick-slider .slick-dots li
{
    width: auto;
    height: auto;
    margin: 0;
}

.slick-slider .slick-dots li + li
{
    margin-left: .75rem;
}

.slick-slider .slick-dots li button
{
    width: 1rem;
    height: 1rem;
    margin: 0;

    border: 0;
    border-radius: 1rem;
    background-color: #eee;
}

.slick-slider .slick-dots li button:hover,
.slick-slider .slick-dots .slick-active button
{
    background-color: #8d634b;
}

.slick-slider .slick-dots li button::before
{
    display: none;
}

.slick-slider .slick-hidden.-arrows .slick-dots,
.slick-slider .slick-hidden.-controls .slide-control
{
    display: none !important;
    visibility: hidden !important;
}

.slick-slider .slick-dotted.slick-slider.slick-hidden.-controls
{
    margin-bottom: 0;
}

.form.-grey .form-control
{
    border: 0 none;
    border-radius: 0;
    background-color: #f3f3f3;
}

.form.-grey .form-control:not(:hover):not(:focus)
{
    box-shadow: none;
}

.form-control.form-control-lg
{
    font-size: 1rem;

    padding-right: 1rem;
    padding-left: 1rem;
}

.form.-white .form-control
{
    border: 0 none;
    border-radius: 0;
    background-color: #fff;
    -webkit-box-shadow: inset 0 -2px 0 0 #e7e7e7;
            box-shadow: inset 0 -2px 0 0 #e7e7e7;
}

.form.-white .form-control:hover
{
    -webkit-box-shadow: inset 0 -2px 0 0 #8d634b;
            box-shadow: inset 0 -2px 0 0 #8d634b;
}

.form.-white .form-control:focus
{
    background-color: #f7f7f7;
    -webkit-box-shadow: inset 0 -2px 0 0 #8d634b;
            box-shadow: inset 0 -2px 0 0 #8d634b;
}

.form.-hidden-labels label
{
    position: absolute;

    overflow: hidden;
    clip: rect(0, 0, 0, 0);

    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;

    border: 0;
}

form div.error
{
    font-size: .8rem;
    line-height: 2;

    display: block;
    clear: both;

    -webkit-animation: fadeIn 1s normal none;
            animation: fadeIn 1s normal none;
    -webkit-animation-timing-function: cubic-bezier(.77, 0, .175, 1);
            animation-timing-function: cubic-bezier(.77, 0, .175, 1);
    white-space: normal;

    color: #e23535;
}

form .text-danger,
form .text-success
{
    font-size: .85rem;
    line-height: 2;
}

input.error,
select.error,
textarea.error
{
    color: #dc7878;
    background-color: #ffe4e4;
    -webkit-box-shadow: inset 0 0 0 1px #dc7878;
            box-shadow: inset 0 0 0 1px #dc7878;
}

input.success,
select.success,
textarea.success
{
    color: #49b381;
    background-color: #ebfff9;
    -webkit-box-shadow: inset 0 0 0 1px #49b381;
            box-shadow: inset 0 0 0 1px #49b381;
}

.styled-list
{
    font-size: 1rem;

    padding-left: 1rem;

    list-style-position: outside;
    list-style-type: disc;
}

.dropdown-menu
{
    font-size: inherit;

    animation: animeDropdown .36s 0s normal both;
    animation-timing-function: cubic-bezier(.16, .56, .17, .999);
}

/* 
  * BOOTSTRAP 4 reset
  */
b,
option,
strong
{
    font-weight: 700;
}

label
{
    margin-bottom: 0;

    cursor: pointer;
}

.lead
{
    font-size: 1rem;
}

.form-row
{
    margin-bottom: 1rem;
}

.navbar
{
    padding-right: 0;
    padding-left: 0;
}

.nav-pills .nav-link
{
    border-radius: 0;
}

.btn-primary
{
    color: white;
}

.list-menu li
{
    line-height: 2rem;
}

.fs-normal
{
    font-size: 1rem;
}

.fs-small
{
    font-size: small;
}

.fs-medium
{
    font-size: 1.25rem;
}

.fs-title
{
    font-size: 1.75rem;
}

.form-group:last-child,
p:last-child,
h1:last-child,
.h1:last-child,
h2:last-child,
.h2:last-child,
h3:last-child,
.h3:last-child,
h4:last-child,
.h4:last-child,
h5:last-child,
.h5:last-child,
h6:last-child,
.h6:last-child
{
    margin-bottom: 0;
}

.pagination
{
    font-family: 'Lora';
    font-weight: normal;
    font-style: italic;
}

.pagination .page-item + .page-item
{
    margin-left: .5rem;
}

.pagination .page-item .page-link
{
    line-height: 2.5rem;

    display: flex;

    width: 2.5rem;
    height: 2.5rem;
    padding: 0;

    color: #8d634b;
    border-radius: 2.5rem;

    justify-content: center;
    align-items: center;
}

.modal-open .modal
{
    background-color: rgba(0, 0, 0, .33);
}

.modal-backdrop
{
    display: none !important;
}

.btn-success
{
    color: white;
}

.fa
{
    line-height: 1;

    display: inline-block;

    vertical-align: top;
}

.embed-responsive img
{
    position: absolute;
    top: 0;
    left: 0;

    width: auto;
    min-width: 100%;
    height: auto;
    min-height: 100%;
}

.list-group-item
{
    background-color: transparent;
}

.list-group-flush .list-group-item + .list-group-item
{
    border: 0;
    border-top: 1px dotted #ddd;
}

.pagination-wrapper
{
    display: flex;

    justify-content: center;
}

@media (min-width: 0) and (max-width: 576px)
{
    .pagination-wrapper
    {
        padding-top: 1rem;
    }
}

@media (min-width: 577px) and (max-width: 992px)
{
    .pagination-wrapper
    {
        padding-top: 1.5rem;
    }
}

@media (min-width: 993px)
{
    .pagination-wrapper
    {
        padding-top: 2rem;
    }
}

.pagination-wrapper .page-link
{
    font-size: 1rem;
    line-height: 1;

    border: none;
    border-radius: 3.5px;
    background: transparent;
    box-shadow: none;
}

.pagination-wrapper .page-link:hover
{
    color: #8d634b;
    background: transparent;
    box-shadow: inset 0 0 0 2px #8d634b;
}

.pagination-wrapper .page-link .fa
{
    font-size: 1.5rem;
    line-height: .53;

    vertical-align: top;
}

.pagination-wrapper .active .page-link
{
    color: white;
    background: #8d634b;
}

.tooltip
{
    font-weight: bold;
}

.breadcrumb
{
    font-weight: 600;
    line-height: 1;

    margin: 0;
    padding: .5rem 0 0 0;

    background-color: inherit;

    justify-content: center;
}

.breadcrumb li
{
    display: flex;

    align-items: center;
    flex-flow: row nowrap;
}

.breadcrumb h1
{
    font-size: inherit;

    display: inline-block;
}

.breadcrumb a
{
    color: #8d634b;
}

.nav-pills .nav-item + .nav-item
{
    margin-left: .5rem;
}

.nav-pills .nav-link
{
    font-size: 13px;
    line-height: 2rem;
    line-height: 1.2468;

    height: 2rem;
    height: auto;
    padding: 0 1rem;
    padding: .5rem 1rem;

    transition: all .4s cubic-bezier(.16, .56, .17, .999);
    text-transform: uppercase;

    color: #8d634b;
    border: 0 none;
    border-radius: 2rem;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #8d634b;
}

.nav-pills .nav-link.-small
{
    line-height: 1.5rem;

    height: 1.5rem;
    padding: 0 .75rem;

    border-radius: 1.5rem;
}

.nav-pills .nav-link.-medium
{
    line-height: 2.5rem;

    height: 2.5rem;
    padding: 0 1.5rem;

    border-radius: 2.5rem;
}

.nav-pills .nav-link.-large
{
    line-height: 3rem;

    height: 3rem;
    padding: 0 2rem;

    border-radius: 3rem;
}

@media only screen and (min-width: 1024px)
{
    .nav-pills .nav-link.-small
    {
        font-size: .75rem;
    }
    .nav-pills .nav-link.-medium
    {
        font-size: 14.5px;
    }
    .nav-pills .nav-link.-large
    {
        font-size: 1.1rem;
    }
}

.nav-pills .nav-link:hover,
.nav-pills .nav-link:active
{
    color: white;
    background-color: #8d634b;
}

.form-row:last-child
{
    margin-bottom: 0;
}

#html .custom-file-label::after
{
    content: 'Buscar';
}

#html .list-group-item:first-child
{
    border-top: 0;
}

#html .list-group-flush .list-group-item + .list-group-item
{
    border-top: 1px solid #ddd;
}

.form-control
{
    color: inherit;
}

.light-gallery .item
{
    cursor: pointer;
}

#html .modal-content
{
    transition: all .4s cubic-bezier(.16, .56, .17, .999);

    border: 0 none;
    box-shadow: 0 24px 38px 3px rgba(0, 0, 0, .05), 0 9px 46px 8px rgba(0, 0, 0, .05), 0 11px 15px -7px rgba(0, 0, 0, .08);
}

#html .modal-content .modal-body
{
    padding: 2rem;
}

#html .modal-open .modal
{
    background-color: rgba(0, 0, 0, .5);
}

.fa,
.fab,
.far
{
    text-shadow: none !important;
}

body
{
    font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
    font-size: 1rem;
    font-style: normal;

    color: white;
}

@media (min-width: 0) and (max-width: 576px)
{
    body
    {
        line-height: 1.5;
    }
}

@media (min-width: 577px) and (max-width: 767px)
{
    body
    {
        line-height: 1.4;
    }
}

@media (min-width: 768px) and (max-width: 992px)
{
    body
    {
        line-height: 1.3;
    }
}

@media (min-width: 993px)
{
    body
    {
        font-size: 14px;
        line-height: 1.2;
    }
}

@media (min-width: 0) and (max-width: 576px)
{
    #html .section:not(.-p)
    {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
}

@media (min-width: 577px) and (max-width: 992px)
{
    #html .section:not(.-p)
    {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
}

@media (min-width: 993px)
{
    #html .section:not(.-p)
    {
        margin-top: 4rem;
        margin-bottom: 4rem;
    }
}

@media (min-width: 0) and (max-width: 576px)
{
    #html .section.-p
    {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
    }
}

@media (min-width: 577px) and (max-width: 992px)
{
    #html .section.-p
    {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
}

@media (min-width: 993px)
{
    #html .section.-p
    {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
}

.section-hero.-center
{
    text-align: center;
}

.section-hero.-right
{
    text-align: right;
}

@media (min-width: 576px)
{
    .section-hero.-form
    {
        display: flex;

        align-items: center;
    }
    .section-hero.-form .title
    {
        font-size: 2rem;

        margin-bottom: 0;
    }
}

.section-hero.-form .title
{
    font-size: 1.5rem;

    margin-bottom: 2rem;
}

@media (min-width: 0) and (max-width: 992px)
{
    .section-hero
    {
        margin-bottom: 1.5rem;
    }
}

@media (min-width: 993px)
{
    .section-hero
    {
        margin-bottom: 2rem;
    }
}

.section-hero .title
{
    font-family: 'PT Serif', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-weight: 200;
    font-weight: bold;
    font-style: normal;
    line-height: 1.15;

    margin-bottom: 0;

    text-transform: uppercase;
}

.section-hero .title.-main
{
    color: #8d634b;
}

@media (min-width: 0) and (max-width: 576px)
{
    .section-hero .title
    {
        font-size: 1.75rem;
    }
}

@media (min-width: 577px) and (max-width: 992px)
{
    .section-hero .title
    {
        font-size: 2rem;
    }
}

@media (min-width: 993px)
{
    .section-hero .title
    {
        font-size: 2.75rem;
    }
}

.section-hero .title.-line
{
    position: relative;
}

.section-hero .title.-line::after
{
    position: absolute;
    top: 50%;
    left: 0;

    display: block;

    width: 100%;

    content: ' ';

    opacity: .1;
    border: 0;
    border-color: inherit;
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

.section-hero .subtitle
{
    font-family: 'PT Serif', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-weight: 200;
    font-style: normal;
    line-height: 1.15;
}

.section-hero .subtitle.-main
{
    color: #8d634b;
}

@media (min-width: 0) and (max-width: 576px)
{
    .section-hero .subtitle
    {
        font-size: 1rem;
    }
}

@media (min-width: 577px) and (max-width: 992px)
{
    .section-hero .subtitle
    {
        font-size: 1.25rem;
    }
}

@media (min-width: 993px)
{
    .section-hero .subtitle
    {
        font-size: 18px;
    }
}

.section-hero .title + .subtitle
{
    margin-top: .5rem;
}

@media (min-width: 576px)
{
    .section-hero .form
    {
        margin-left: 3rem;
    }
}

.section-hero .form .form-control
{
    font-weight: 700;

    min-width: 300px;

    letter-spacing: 2px;

    background-color: black;
    box-shadow: none;
}

.page.-home .main-navigation .nav-item.-home .nav-link
{
    font-weight: bold;

    color: white;
}

.page.-home .nav-item.-home .nav-link
{
    color: #8d634b;
    text-shadow: 0 0 0;
}

.page.-home #mainFooter .nav-item.-home .nav-link
{
    color: white;
    text-shadow: 0 0 0;
}

.page.-quem-somos .main-navigation .nav-item.-quem-somos .nav-link
{
    font-weight: bold;

    color: white;
}

.page.-quem-somos .nav-item.-quem-somos .nav-link
{
    color: #8d634b;
    text-shadow: 0 0 0;
}

.page.-quem-somos #mainFooter .nav-item.-quem-somos .nav-link
{
    color: white;
    text-shadow: 0 0 0;
}

.page.-casas .main-navigation .nav-item.-casas .nav-link
{
    font-weight: bold;

    color: white;
}

.page.-casas .nav-item.-casas .nav-link
{
    color: #8d634b;
    text-shadow: 0 0 0;
}

.page.-casas #mainFooter .nav-item.-casas .nav-link
{
    color: white;
    text-shadow: 0 0 0;
}

.page.-lotes .main-navigation .nav-item.-lotes .nav-link
{
    font-weight: bold;

    color: white;
}

.page.-lotes .nav-item.-lotes .nav-link
{
    color: #8d634b;
    text-shadow: 0 0 0;
}

.page.-lotes #mainFooter .nav-item.-lotes .nav-link
{
    color: white;
    text-shadow: 0 0 0;
}

.page.-imovel .main-navigation .nav-item.-imovel .nav-link
{
    font-weight: bold;

    color: white;
}

.page.-imovel .nav-item.-imovel .nav-link
{
    color: #8d634b;
    text-shadow: 0 0 0;
}

.page.-imovel #mainFooter .nav-item.-imovel .nav-link
{
    color: white;
    text-shadow: 0 0 0;
}

.page.-aluguel .main-navigation .nav-item.-aluguel .nav-link
{
    font-weight: bold;

    color: white;
}

.page.-aluguel .nav-item.-aluguel .nav-link
{
    color: #8d634b;
    text-shadow: 0 0 0;
}

.page.-aluguel #mainFooter .nav-item.-aluguel .nav-link
{
    color: white;
    text-shadow: 0 0 0;
}

.page.-condominio .main-navigation .nav-item.-condominio .nav-link
{
    font-weight: bold;

    color: white;
}

.page.-condominio .nav-item.-condominio .nav-link
{
    color: #8d634b;
    text-shadow: 0 0 0;
}

.page.-condominio #mainFooter .nav-item.-condominio .nav-link
{
    color: white;
    text-shadow: 0 0 0;
}

html .c-main
{
    color: #8d634b !important;
}

html .bc-main
{
    background-color: #8d634b !important;
}

html .btn-main
{
    color: white;
    background-color: #8d634b;
}

html .btn-main:hover
{
    color: white;
    background-color: tint(#8d634b, 10%);
}

html .btn-main.active,
html .btn-main:active
{
    color: white;
    background-color: shade(#8d634b, 5%);
}

html .btn-main:focus
{
    color: white;
    box-shadow: 0 0 0 .2rem rgba(141, 99, 75, .25);
}

html .btn-border-main
{
    color: #8d634b;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #8d634b;
}

html .btn-border-main:hover,
html .btn-border-main:active
{
    color: white;
    background-color: #8d634b;
}

html .btn-border-main:focus
{
    box-shadow: 0 0 0 .2rem rgba(141, 99, 75, .25);
}

html .c-primary
{
    color: #8d634b !important;
}

html .bc-primary
{
    background-color: #8d634b !important;
}

html .btn-primary
{
    color: white;
    background-color: #8d634b;
}

html .btn-primary:hover
{
    color: white;
    background-color: tint(#8d634b, 10%);
}

html .btn-primary.active,
html .btn-primary:active
{
    color: white;
    background-color: shade(#8d634b, 5%);
}

html .btn-primary:focus
{
    color: white;
    box-shadow: 0 0 0 .2rem rgba(141, 99, 75, .25);
}

html .btn-border-primary
{
    color: #8d634b;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #8d634b;
}

html .btn-border-primary:hover,
html .btn-border-primary:active
{
    color: white;
    background-color: #8d634b;
}

html .btn-border-primary:focus
{
    box-shadow: 0 0 0 .2rem rgba(141, 99, 75, .25);
}

html .c-contrast
{
    color: #231f20 !important;
}

html .bc-contrast
{
    background-color: #231f20 !important;
}

html .btn-contrast
{
    color: white;
    background-color: #231f20;
}

html .btn-contrast:hover
{
    color: white;
    background-color: tint(#231f20, 10%);
}

html .btn-contrast.active,
html .btn-contrast:active
{
    color: white;
    background-color: shade(#231f20, 5%);
}

html .btn-contrast:focus
{
    color: white;
    box-shadow: 0 0 0 .2rem rgba(35, 31, 32, .25);
}

html .btn-border-contrast
{
    color: #231f20;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #231f20;
}

html .btn-border-contrast:hover,
html .btn-border-contrast:active
{
    color: white;
    background-color: #231f20;
}

html .btn-border-contrast:focus
{
    box-shadow: 0 0 0 .2rem rgba(35, 31, 32, .25);
}

html .c-secondary
{
    color: #231f20 !important;
}

html .bc-secondary
{
    background-color: #231f20 !important;
}

html .btn-secondary
{
    color: white;
    background-color: #231f20;
}

html .btn-secondary:hover
{
    color: white;
    background-color: tint(#231f20, 10%);
}

html .btn-secondary.active,
html .btn-secondary:active
{
    color: white;
    background-color: shade(#231f20, 5%);
}

html .btn-secondary:focus
{
    color: white;
    box-shadow: 0 0 0 .2rem rgba(35, 31, 32, .25);
}

html .btn-border-secondary
{
    color: #231f20;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #231f20;
}

html .btn-border-secondary:hover,
html .btn-border-secondary:active
{
    color: white;
    background-color: #231f20;
}

html .btn-border-secondary:focus
{
    box-shadow: 0 0 0 .2rem rgba(35, 31, 32, .25);
}

html .c-dark
{
    color: #000 !important;
}

html .bc-dark
{
    background-color: #000 !important;
}

html .btn-dark
{
    color: white;
    background-color: #000;
}

html .btn-dark:hover
{
    color: white;
    background-color: tint(#000, 10%);
}

html .btn-dark.active,
html .btn-dark:active
{
    color: white;
    background-color: shade(#000, 5%);
}

html .btn-dark:focus
{
    color: white;
    box-shadow: 0 0 0 .2rem rgba(0, 0, 0, .25);
}

html .btn-border-dark
{
    color: #000;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #000;
}

html .btn-border-dark:hover,
html .btn-border-dark:active
{
    color: white;
    background-color: #000;
}

html .btn-border-dark:focus
{
    box-shadow: 0 0 0 .2rem rgba(0, 0, 0, .25);
}

html .c-light
{
    color: #eee !important;
}

html .bc-light
{
    background-color: #eee !important;
}

html .btn-light
{
    color: white;
    background-color: #eee;
}

html .btn-light:hover
{
    color: white;
    background-color: tint(#eee, 10%);
}

html .btn-light.active,
html .btn-light:active
{
    color: white;
    background-color: shade(#eee, 5%);
}

html .btn-light:focus
{
    color: white;
    box-shadow: 0 0 0 .2rem rgba(238, 238, 238, .25);
}

html .btn-border-light
{
    color: #eee;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #eee;
}

html .btn-border-light:hover,
html .btn-border-light:active
{
    color: white;
    background-color: #eee;
}

html .btn-border-light:focus
{
    box-shadow: 0 0 0 .2rem rgba(238, 238, 238, .25);
}

html .c-green
{
    color: #4caf50 !important;
}

html .bc-green
{
    background-color: #4caf50 !important;
}

html .btn-green
{
    color: white;
    background-color: #4caf50;
}

html .btn-green:hover
{
    color: white;
    background-color: tint(#4caf50, 10%);
}

html .btn-green.active,
html .btn-green:active
{
    color: white;
    background-color: shade(#4caf50, 5%);
}

html .btn-green:focus
{
    color: white;
    box-shadow: 0 0 0 .2rem rgba(76, 175, 80, .25);
}

html .btn-border-green
{
    color: #4caf50;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #4caf50;
}

html .btn-border-green:hover,
html .btn-border-green:active
{
    color: white;
    background-color: #4caf50;
}

html .btn-border-green:focus
{
    box-shadow: 0 0 0 .2rem rgba(76, 175, 80, .25);
}

html .c-grey
{
    color: #e5e5e5 !important;
}

html .bc-grey
{
    background-color: #e5e5e5 !important;
}

html .btn-grey
{
    color: white;
    background-color: #e5e5e5;
}

html .btn-grey:hover
{
    color: white;
    background-color: tint(#e5e5e5, 10%);
}

html .btn-grey.active,
html .btn-grey:active
{
    color: white;
    background-color: shade(#e5e5e5, 5%);
}

html .btn-grey:focus
{
    color: white;
    box-shadow: 0 0 0 .2rem rgba(229, 229, 229, .25);
}

html .btn-border-grey
{
    color: #e5e5e5;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #e5e5e5;
}

html .btn-border-grey:hover,
html .btn-border-grey:active
{
    color: white;
    background-color: #e5e5e5;
}

html .btn-border-grey:focus
{
    box-shadow: 0 0 0 .2rem rgba(229, 229, 229, .25);
}

html .c-red
{
    color: #e53935 !important;
}

html .bc-red
{
    background-color: #e53935 !important;
}

html .btn-red
{
    color: white;
    background-color: #e53935;
}

html .btn-red:hover
{
    color: white;
    background-color: tint(#e53935, 10%);
}

html .btn-red.active,
html .btn-red:active
{
    color: white;
    background-color: shade(#e53935, 5%);
}

html .btn-red:focus
{
    color: white;
    box-shadow: 0 0 0 .2rem rgba(229, 57, 53, .25);
}

html .btn-border-red
{
    color: #e53935;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #e53935;
}

html .btn-border-red:hover,
html .btn-border-red:active
{
    color: white;
    background-color: #e53935;
}

html .btn-border-red:focus
{
    box-shadow: 0 0 0 .2rem rgba(229, 57, 53, .25);
}

html .c-white
{
    color: white !important;
}

html .bc-white
{
    background-color: white !important;
}

html .btn-white
{
    color: white;
    background-color: white;
}

html .btn-white:hover
{
    color: white;
    background-color: tint(white, 10%);
}

html .btn-white.active,
html .btn-white:active
{
    color: white;
    background-color: shade(white, 5%);
}

html .btn-white:focus
{
    color: white;
    box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .25);
}

html .btn-border-white
{
    color: white;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px white;
}

html .btn-border-white:hover,
html .btn-border-white:active
{
    color: white;
    background-color: white;
}

html .btn-border-white:focus
{
    box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .25);
}

html .c-black
{
    color: black !important;
}

html .bc-black
{
    background-color: black !important;
}

html .btn-black
{
    color: white;
    background-color: black;
}

html .btn-black:hover
{
    color: white;
    background-color: tint(black, 10%);
}

html .btn-black.active,
html .btn-black:active
{
    color: white;
    background-color: shade(black, 5%);
}

html .btn-black:focus
{
    color: white;
    box-shadow: 0 0 0 .2rem rgba(0, 0, 0, .25);
}

html .btn-border-black
{
    color: black;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px black;
}

html .btn-border-black:hover,
html .btn-border-black:active
{
    color: white;
    background-color: black;
}

html .btn-border-black:focus
{
    box-shadow: 0 0 0 .2rem rgba(0, 0, 0, .25);
}

html .c-mutted
{
    color: #aaa !important;
}

html .bc-mutted
{
    background-color: #aaa !important;
}

html .btn-mutted
{
    color: white;
    background-color: #aaa;
}

html .btn-mutted:hover
{
    color: white;
    background-color: tint(#aaa, 10%);
}

html .btn-mutted.active,
html .btn-mutted:active
{
    color: white;
    background-color: shade(#aaa, 5%);
}

html .btn-mutted:focus
{
    color: white;
    box-shadow: 0 0 0 .2rem rgba(170, 170, 170, .25);
}

html .btn-border-mutted
{
    color: #aaa;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #aaa;
}

html .btn-border-mutted:hover,
html .btn-border-mutted:active
{
    color: white;
    background-color: #aaa;
}

html .btn-border-mutted:focus
{
    box-shadow: 0 0 0 .2rem rgba(170, 170, 170, .25);
}

html .c-facebook
{
    color: #3c5a99 !important;
}

html .bc-facebook
{
    background-color: #3c5a99 !important;
}

html .btn-facebook
{
    color: white;
    background-color: #3c5a99;
}

html .btn-facebook:hover
{
    color: white;
    background-color: tint(#3c5a99, 10%);
}

html .btn-facebook.active,
html .btn-facebook:active
{
    color: white;
    background-color: shade(#3c5a99, 5%);
}

html .btn-facebook:focus
{
    color: white;
    box-shadow: 0 0 0 .2rem rgba(60, 90, 153, .25);
}

html .btn-border-facebook
{
    color: #3c5a99;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #3c5a99;
}

html .btn-border-facebook:hover,
html .btn-border-facebook:active
{
    color: white;
    background-color: #3c5a99;
}

html .btn-border-facebook:focus
{
    box-shadow: 0 0 0 .2rem rgba(60, 90, 153, .25);
}

html .c-twitter
{
    color: #1da1f2 !important;
}

html .bc-twitter
{
    background-color: #1da1f2 !important;
}

html .btn-twitter
{
    color: white;
    background-color: #1da1f2;
}

html .btn-twitter:hover
{
    color: white;
    background-color: tint(#1da1f2, 10%);
}

html .btn-twitter.active,
html .btn-twitter:active
{
    color: white;
    background-color: shade(#1da1f2, 5%);
}

html .btn-twitter:focus
{
    color: white;
    box-shadow: 0 0 0 .2rem rgba(29, 161, 242, .25);
}

html .btn-border-twitter
{
    color: #1da1f2;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #1da1f2;
}

html .btn-border-twitter:hover,
html .btn-border-twitter:active
{
    color: white;
    background-color: #1da1f2;
}

html .btn-border-twitter:focus
{
    box-shadow: 0 0 0 .2rem rgba(29, 161, 242, .25);
}

html .c-googleplus
{
    color: #db4437 !important;
}

html .bc-googleplus
{
    background-color: #db4437 !important;
}

html .btn-googleplus
{
    color: white;
    background-color: #db4437;
}

html .btn-googleplus:hover
{
    color: white;
    background-color: tint(#db4437, 10%);
}

html .btn-googleplus.active,
html .btn-googleplus:active
{
    color: white;
    background-color: shade(#db4437, 5%);
}

html .btn-googleplus:focus
{
    color: white;
    box-shadow: 0 0 0 .2rem rgba(219, 68, 55, .25);
}

html .btn-border-googleplus
{
    color: #db4437;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #db4437;
}

html .btn-border-googleplus:hover,
html .btn-border-googleplus:active
{
    color: white;
    background-color: #db4437;
}

html .btn-border-googleplus:focus
{
    box-shadow: 0 0 0 .2rem rgba(219, 68, 55, .25);
}

html .c-instagram
{
    color: #c13584 !important;
}

html .bc-instagram
{
    background-color: #c13584 !important;
}

html .btn-instagram
{
    color: white;
    background-color: #c13584;
}

html .btn-instagram:hover
{
    color: white;
    background-color: tint(#c13584, 10%);
}

html .btn-instagram.active,
html .btn-instagram:active
{
    color: white;
    background-color: shade(#c13584, 5%);
}

html .btn-instagram:focus
{
    color: white;
    box-shadow: 0 0 0 .2rem rgba(193, 53, 132, .25);
}

html .btn-border-instagram
{
    color: #c13584;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #c13584;
}

html .btn-border-instagram:hover,
html .btn-border-instagram:active
{
    color: white;
    background-color: #c13584;
}

html .btn-border-instagram:focus
{
    box-shadow: 0 0 0 .2rem rgba(193, 53, 132, .25);
}

html .c-youtube
{
    color: #ff0416 !important;
}

html .bc-youtube
{
    background-color: #ff0416 !important;
}

html .btn-youtube
{
    color: white;
    background-color: #ff0416;
}

html .btn-youtube:hover
{
    color: white;
    background-color: tint(#ff0416, 10%);
}

html .btn-youtube.active,
html .btn-youtube:active
{
    color: white;
    background-color: shade(#ff0416, 5%);
}

html .btn-youtube:focus
{
    color: white;
    box-shadow: 0 0 0 .2rem rgba(255, 4, 22, .25);
}

html .btn-border-youtube
{
    color: #ff0416;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #ff0416;
}

html .btn-border-youtube:hover,
html .btn-border-youtube:active
{
    color: white;
    background-color: #ff0416;
}

html .btn-border-youtube:focus
{
    box-shadow: 0 0 0 .2rem rgba(255, 4, 22, .25);
}

html .c-linkedin
{
    color: #0077b5 !important;
}

html .bc-linkedin
{
    background-color: #0077b5 !important;
}

html .btn-linkedin
{
    color: white;
    background-color: #0077b5;
}

html .btn-linkedin:hover
{
    color: white;
    background-color: tint(#0077b5, 10%);
}

html .btn-linkedin.active,
html .btn-linkedin:active
{
    color: white;
    background-color: shade(#0077b5, 5%);
}

html .btn-linkedin:focus
{
    color: white;
    box-shadow: 0 0 0 .2rem rgba(0, 119, 181, .25);
}

html .btn-border-linkedin
{
    color: #0077b5;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #0077b5;
}

html .btn-border-linkedin:hover,
html .btn-border-linkedin:active
{
    color: white;
    background-color: #0077b5;
}

html .btn-border-linkedin:focus
{
    box-shadow: 0 0 0 .2rem rgba(0, 119, 181, .25);
}

html .c-pinterest
{
    color: #bd081c !important;
}

html .bc-pinterest
{
    background-color: #bd081c !important;
}

html .btn-pinterest
{
    color: white;
    background-color: #bd081c;
}

html .btn-pinterest:hover
{
    color: white;
    background-color: tint(#bd081c, 10%);
}

html .btn-pinterest.active,
html .btn-pinterest:active
{
    color: white;
    background-color: shade(#bd081c, 5%);
}

html .btn-pinterest:focus
{
    color: white;
    box-shadow: 0 0 0 .2rem rgba(189, 8, 28, .25);
}

html .btn-border-pinterest
{
    color: #bd081c;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #bd081c;
}

html .btn-border-pinterest:hover,
html .btn-border-pinterest:active
{
    color: white;
    background-color: #bd081c;
}

html .btn-border-pinterest:focus
{
    box-shadow: 0 0 0 .2rem rgba(189, 8, 28, .25);
}

html .c-whatsapp
{
    color: #25d366 !important;
}

html .bc-whatsapp
{
    background-color: #25d366 !important;
}

html .btn-whatsapp
{
    color: white;
    background-color: #25d366;
}

html .btn-whatsapp:hover
{
    color: white;
    background-color: tint(#25d366, 10%);
}

html .btn-whatsapp.active,
html .btn-whatsapp:active
{
    color: white;
    background-color: shade(#25d366, 5%);
}

html .btn-whatsapp:focus
{
    color: white;
    box-shadow: 0 0 0 .2rem rgba(37, 211, 102, .25);
}

html .btn-border-whatsapp
{
    color: #25d366;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #25d366;
}

html .btn-border-whatsapp:hover,
html .btn-border-whatsapp:active
{
    color: white;
    background-color: #25d366;
}

html .btn-border-whatsapp:focus
{
    box-shadow: 0 0 0 .2rem rgba(37, 211, 102, .25);
}

html .btn
{
    font-size: 13px;
    line-height: 2rem;

    height: 2rem;
    padding: 0 1rem;

    transition: all .4s cubic-bezier(.16, .56, .17, .999);
    text-transform: uppercase;

    border: 0 none;
    border-radius: 2rem;
}

html .btn.-small
{
    line-height: 1.5rem;

    height: 1.5rem;
    padding: 0 .75rem;

    border-radius: 1.5rem;
}

html .btn.-medium
{
    line-height: 2.5rem;

    height: 2.5rem;
    padding: 0 1.5rem;

    border-radius: 2.5rem;
}

html .btn.-large
{
    line-height: 3rem;

    height: 3rem;
    padding: 0 2rem;

    border-radius: 3rem;
}

@media only screen and (min-width: 1024px)
{
    html .btn.-small
    {
        font-size: .75rem;
    }
    html .btn.-medium
    {
        font-size: 14.5px;
    }
    html .btn.-large
    {
        font-size: 1.1rem;
    }
}

.dropdown-menu
{
    border: none;
    box-shadow: 0 8px 17px 2px rgba(0, 0, 0, .05), 0 3px 14px 2px rgba(0, 0, 0, .05), 0 5px 5px -3px rgba(0, 0, 0, .08);
}

.box-card
{
    display: flex;

    padding: 1rem;

    transition: all .4s cubic-bezier(.16, .56, .17, .999);

    border-radius: .5rem;
    background-color: white;
    box-shadow: 0 8px 17px 2px rgba(0, 0, 0, .025), 0 3px 14px 2px rgba(0, 0, 0, .025), 0 5px 5px -3px rgba(248, 186, 186, .05);

    flex-flow: column nowrap;
}

@media only screen and (min-width: 1024px)
{
    .box-card
    {
        padding: 2rem;
    }
}

.box-card:hover
{
    box-shadow: 0 8px 17px 2px rgba(0, 0, 0, .05), 0 3px 14px 2px rgba(0, 0, 0, .05), 0 5px 5px -3px rgba(248, 186, 186, .08);
}

.box-card.-center
{
    max-width: 320px;
    margin: auto;
}

@media only screen and (min-width: 1024px)
{
    .box-card.-center
    {
        max-width: 460px;
        padding: 2rem;
    }
}

.datetime
{
    font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
    font-weight: bold;
    font-style: normal;

    color: #aaa;
}

.embed-responsive.-A4::before
{
    padding-top: 143%;
}

.form .title
{
    font-family: 'PT Serif', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 1.25rem;
    font-weight: 200;
    font-style: normal;
    line-height: 1.15;
}

@media (max-width: 575.98px)
{
    .col-md-4 + .col-md-8,
    .col-md-8 + .col-md-4,
    .col-md-6 + .col-md-6
    {
        margin-top: 3rem;
    }
    .col-md-4 + .col-md-8.form-group,
    .col-md-8 + .col-md-4.form-group,
    .col-md-6 + .col-md-6.form-group
    {
        margin-top: 0;
    }
}

.mm-menu
{
    color: #eee;
    background: #000;
}

.mm-menu .mm-navbar .mm-navbar__title
{
    font-family: 'PT Serif', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-weight: 200;
    font-style: normal;
    line-height: 1.15;
}

.mm-menu .mm-navbar a
{
    color: inherit !important;
}

.mm-menu .mm-navbars_bottom .fa
{
    font-size: 1.5rem;
}

.mm-menu .mobile-navigation
{
    font-weight: bold;
}

.mm-menu .mobile-navigation .title
{
    font-family: 'PT Serif', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 1.5rem;
    font-weight: 200;
    font-style: normal;
    line-height: 1.15;

    margin-top: 1rem;
    padding: 15px 20px;

    color: inherit !important;
    background-color: #050505;
}

.theme-dl.-box
{
    display: flex;

    padding: 1rem;

    transition: all .4s cubic-bezier(.16, .56, .17, .999);

    border-radius: .5rem;
    background-color: white;
    box-shadow: 0 8px 17px 2px rgba(0, 0, 0, .025), 0 3px 14px 2px rgba(0, 0, 0, .025), 0 5px 5px -3px rgba(248, 186, 186, .05);

    flex-flow: column nowrap;
}

@media only screen and (min-width: 1024px)
{
    .theme-dl.-box
    {
        padding: 2rem;
    }
}

.theme-dl.-box:hover
{
    box-shadow: 0 8px 17px 2px rgba(0, 0, 0, .05), 0 3px 14px 2px rgba(0, 0, 0, .05), 0 5px 5px -3px rgba(248, 186, 186, .08);
}

.theme-dl dt
{
    font-size: 12px;
    font-weight: normal;
    font-weight: 600;
    line-height: 1;

    text-transform: uppercase;

    color: #aaa;
}

.theme-dl dd
{
    font-weight: bold;
}

.theme-dl dd + dt
{
    margin-top: 1rem;
}

.theme-dl .title
{
    font-family: 'PT Serif', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 1.25rem;
    font-weight: 200;
    font-style: normal;
    line-height: 1.15;
}

.page-title
{
    display: flex;
    overflow: hidden;

    height: 300px;
    padding: 0 0 4rem 0;

    background-color: #000;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-size: cover;

    align-items: flex-end;
    justify-content: center;
}

@media (min-width: 577px)
{
    .page-title
    {
        height: 420px;
    }
}

.page-title.-overlay
{
    position: relative;
}

.page-title.-overlay::after
{
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;

    display: block;

    width: 100%;
    height: 100%;

    content: ' ';

    background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, .8) 80%);
}

.page-title.-overlay .container
{
    z-index: 2;
}

.page-title .title
{
    font-family: 'PT Serif', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-weight: 200;
    font-weight: bolder;
    font-style: normal;
    line-height: 1.15;

    margin-bottom: 0;

    text-transform: uppercase;

    color: white;
}

.page-title .title::after
{
    display: block;

    width: 4rem;
    height: 1px;
    margin: 1rem auto 0;

    content: ' ';

    border-bottom: 1px solid white;
}

@media (min-width: 992px)
{
    .page-title .title::after
    {
        width: 7rem;
    }
}

.page-title .title.-main
{
    color: #8d634b;
}

.page-title .title.-dark
{
    color: #000;
}

@media (min-width: 0) and (max-width: 576px)
{
    .page-title .title
    {
        font-size: 2.5rem;
    }
}

@media (min-width: 577px) and (max-width: 991.98px)
{
    .page-title .title
    {
        font-size: 3rem;
    }
}

@media (min-width: 992px)
{
    .page-title .title
    {
        font-size: 4rem;

        padding: 0 15%;
    }
}

.page-title .subtitle
{
    font-family: 'PT Serif', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-weight: 200;
    font-style: normal;
    line-height: 1.15;
}

@media (min-width: 0) and (max-width: 576px)
{
    .page-title .subtitle
    {
        font-size: 1rem;
    }
}

@media (min-width: 577px) and (max-width: 767px)
{
    .page-title .subtitle
    {
        font-size: 1.25rem;
    }
}

@media (min-width: 768px) and (max-width: 991.98px)
{
    .page-title .subtitle
    {
        font-size: 1.5rem;
    }
}

@media (min-width: 992px)
{
    .page-title .subtitle
    {
        font-size: 1rem;
    }
}

.preloader-wrapper
{
    position: fixed;
    z-index: 99998748;
    top: 0;
    left: 0;

    display: flex;

    width: 100%;
    height: 100%;

    background-color: #eee;

    align-items: center;
    justify-content: center;
}

.preloader-ring
{
    display: block;

    width: 64px;
    height: 64px;
}

.preloader-ring:after
{
    display: block;

    width: 64px;
    height: 64px;
    margin: 1px;

    content: ' ';
    animation: preloader-animation 1s cubic-bezier(.16, .56, .17, .7) infinite;

    border: 5px solid #8d634b;
    border-color: #8d634b transparent #8d634b transparent;
    border-radius: 50%;
}

@keyframes preloader-animation
{
    0%
    {
        transform: rotate(0deg);
    }
    100%
    {
        transform: rotate(360deg);
    }
}

.article-body .title
{
    font-family: 'PT Serif', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 1.5rem;
    font-weight: 200;
    font-style: normal;
    line-height: 1.15;

    margin-bottom: 1.5rem;

    color: #8d634b;
}

@media (min-width: 576px)
{
    .article-body .title
    {
        font-size: 2rem;
    }
}

.article-body p
{
    font-size: 1.25rem;

    margin-bottom: 1.25rem;

    color: #aaa;
}

.article-body p:last-child
{
    margin-bottom: 0;
}

.article-body ul
{
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    padding-left: 19px;

    list-style: circle;
}

.article-body ul li
{
    font-size: 1.1rem;

    margin-bottom: 0;
}

.article-body ul li + li
{
    margin-top: .5rem;
}

.article-body .embed-responsive
{
    margin-bottom: 2rem;
}

@media (min-width: 576px)
{
    .article-body .embed-responsive
    {
        margin-top: 2rem;
    }
}

.article-body .embed-responsive:last-child
{
    margin-bottom: 0;
}

#html .slick-slider .slick-current
{
    position: relative;
    z-index: 2;
}

#html .slick-slider .slick-counter
{
    font-size: 1.5rem;
    font-weight: 500;
    line-height: .8;

    display: flex;

    margin-top: 1rem;

    color: #8d634b;

    justify-content: center;
    align-items: center;
}

#html .slick-slider .slick-counter .current
{
    font-size: 2.5rem;

    color: #000;
}

#html .slick-slider .slick-counter .total
{
    padding-left: .5rem;
}

#html .slick-slider .slick-counter .total::before
{
    display: inline-block;

    content: '/';
}

#html .slick-slider .slide-control
{
    box-shadow: none;
}

@media (max-width: 991.98px)
{
    #html .slick-slider .slide-control
    {
        zoom: .65;
    }
}

#html .slick-slider .slide-control.-next .fa::before
{
    display: block;

    content: url(../img/icone-right.svg);
}

#html .slick-slider .slide-control.-prev .fa::before
{
    display: block;

    content: url(../img/icone-left.svg);
}

body
{
    background-color: #231f20;
}

.page
{
    background-image: url(../img/bg-page.png);
    background-repeat: no-repeat;
    background-position: center top;
}

h1,
h2,
h3,
h4,
h5,
h6
{
    font-family: 'PT Serif', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-weight: 200;
    font-style: normal;
}

.container-fluid
{
    padding-right: 2rem;
    padding-left: 2rem;
}

.main-header
{
    z-index: 100;

    padding-top: .5rem;
    padding-bottom: .5rem;
}

@media (min-width: 576px)
{
    .main-header
    {
        margin-bottom: 1rem;
        padding-top: 1rem;
        padding-bottom: 0;
    }
}

.main-header.is-fixed
{
    position: fixed;
    z-index: 1030;
    top: 0;
    right: 0;
    left: 0;

    padding: .5rem 0;

    animation: animeStickyHeader .618s 0s normal both;
    animation-timing-function: cubic-bezier(.16, .56, .17, .999);

    background-color: black;
    box-shadow: 0 12px 17px 2px rgba(0, 0, 0, .14), 0 5px 22px 4px rgba(0, 0, 0, .12), 0 7px 8px -4px rgba(0, 0, 0, .2);
}

.main-header.is-fixed .logo
{
    width: auto;
    max-height: 2rem !important;
}

.main-header.is-fixed .main-navigation
{
    padding: 0;

    white-space: nowrap;
}

.main-header.is-fixed .main-navigation .nav-link
{
    font-size: 14px;
}

.main-header.is-fixed .header-contato
{
    display: none;
}

.main-header .header-main-content
{
    display: flex;

    justify-content: space-between;
    align-items: center;
}

.main-header .header-logo-container .logo
{
    max-width: 200px;
}

@media (min-width: 576px)
{
    .main-header .header-logo-container .logo
    {
        max-width: 256px;
    }
}

.main-header .header-menu-container
{
    color: #ccc;
}

.main-header .main-navigation
{
    padding-bottom: 0;

    letter-spacing: 2px;
}

.main-header .main-navigation .current_page_item
{
    color: white;
    text-shadow: 0 0 0;
}

.main-header .main-navigation .nav-link
{
    font-weight: 600;

    text-transform: uppercase;
}

.main-header .main-navigation .nav-link:hover
{
    font-weight: bold;

    color: white;
}

.main-header .header-contato
{
    padding-right: 1rem;

    text-align: right;
}

.main-header .header-contato .fab
{
    font-size: 1rem;

    margin-left: .5rem;
}

.main-header .nav-social
{
    display: none;

    padding-right: 1rem;

    justify-content: flex-end;
}

.page.-transparent-header .main-header:not(.is-fixed)
{
    position: absolute;

    width: 100%;
    padding: 1rem 0;

    background: transparent;
}

@media (min-width: 576px)
{
    .page.-transparent-header .main-header:not(.is-fixed)
    {
        padding: 1rem;
    }
}

.page.-transparent-header .main-header:not(.is-fixed)::before
{
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;

    width: 100%;
    height: 200%;

    content: ' ';
    pointer-events: none;

    background-image: linear-gradient(to top, transparent, rgba(39, 30, 25, .8));
}

.nav-social
{
    margin-top: 1rem;
    padding: 0;
}

@media (min-width: 576px)
{
    .nav-social .nav-item + .nav-item
    {
        margin-top: 0;
        margin-left: 2rem;
    }
}

.nav-social .nav-link
{
    display: flex;

    padding: 0;

    vertical-align: top;

    align-items: center;
}

.nav-social .nav-link a,
.nav-social .nav-link span
{
    font-size: 14px;

    margin-left: .5rem;
}

.main-footer
{
    padding-top: 3rem;
    padding-bottom: 3rem;

    background-color: black;
}

@media (min-width: 576px)
{
    .main-footer
    {
        padding-top: 0;

        background-color: transparent;
    }
}

.main-footer .row
{
    align-items: center;
}

.main-footer .logo-footer
{
    display: block;

    text-align: center;
}

@media (min-width: 576px)
{
    .main-footer .logo-footer
    {
        display: inline;

        text-align: inherit;
    }
}

.main-footer .logo-ilion
{
    opacity: .3;
}

.main-footer .logo-ilion:hover
{
    opacity: .6;
}

.main-footer .nav-social
{
    margin-bottom: 1rem;

    justify-content: center;
}

@media (min-width: 576px)
{
    .main-footer .nav-social
    {
        justify-content: flex-start;
    }
}

.main-footer .nav-social .nav-item + .nav-item
{
    margin-left: 1rem;
}

.main-footer .nav-social .nav-item.-whatsapp:hover
{
    color: #25d366;
}

.main-footer .nav-social .nav-link
{
    font-size: 1.5rem;
}

.main-footer .copyright
{
    margin-bottom: 2rem;

    text-align: center;
    text-transform: uppercase;

    color: #aaa;
}

@media (min-width: 576px)
{
    .main-footer .copyright
    {
        margin-bottom: 0;

        text-align: left;
    }
}

.listagem-imoveis .item-imovel
{
    padding: 1rem;
}

.listagem-imoveis .imovel
{
    transition: all .4s cubic-bezier(.16, .56, .17, .999);

    background-color: #000;
    background-image: url(../img/logo-color.png);
    background-repeat: no-repeat;
    background-position: top 3rem center;
    background-size: auto 20%;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12), 0 3px 5px -1px rgba(0, 0, 0, .2);
}

.listagem-imoveis .imovel:hover
{
    box-shadow: 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12), 0 5px 5px -3px rgba(0, 0, 0, .2);
    box-shadow: 0 0 0 2px #8d634b;
}

.listagem-imoveis .imovel:hover .img
{
    opacity: 1;
}

.listagem-imoveis .imovel .content
{
    display: flex;

    padding: 1rem;

    transition: all .4s cubic-bezier(.16, .56, .17, .999);

    background-image: linear-gradient(to bottom, transparent 50%, #000);

    justify-content: flex-end;
    flex-flow: column nowrap;
}

@media (min-width: 1600px)
{
    .listagem-imoveis .imovel .content
    {
        padding: 2rem;
    }
}

.listagem-imoveis .imovel .content .title
{
    font-family: 'PT Serif', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 1.5rem;
    font-weight: 200;
    font-style: normal;
    line-height: 1.15;

    margin-bottom: .5rem;
}

@media (min-width: 576px)
{
    .listagem-imoveis .imovel .content .title
    {
        font-size: 1.7rem;
    }
}

.listagem-imoveis .imovel .content .info
{
    font-size: 1rem;
    line-height: 1;

    display: flex;

    align-items: center;
}

.listagem-imoveis .imovel .content .info span + span
{
    margin-left: 1rem;
}

.listagem-imoveis .imovel .content .info .info-preco
{
    font-size: 1.25rem;
    font-weight: bold;

    color: #8d634b;
    text-shadow: none !important;
}

@media (min-width: 576px)
{
    .listagem-imoveis .imovel .content .info .info-preco
    {
        font-size: 1rem;
        font-weight: normal;
    }
}

@media (min-width: 1600px)
{
    .listagem-imoveis .imovel .content .info .info-preco
    {
        font-size: 1.5rem;
    }
}

.listagem-imoveis .imovel .content .info .info-preco small
{
    font-size: 70%;
}

@media (max-width: 575.98px)
{
    .form-filter
    {
        display: block !important;
    }
    .form-filter .form-group + .form-group
    {
        margin-top: 2rem;
    }
}

.home-banner
{
    overflow: hidden;

    background-image: url(../img/thumbnail-banner.jpg);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-size: cover;
}

.home-banner .embed-responsive
{
    display: flex;

    max-height: 100vh;

    background-color: rgba(51, 37, 32, .5);

    align-items: center;
    justify-content: center;
}

.home-banner .content
{
    text-align: center;
}

.home-banner .content .title
{
    font-family: 'PT Serif', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 4.5rem;
    font-weight: bold;
    font-style: normal;
    line-height: 1;

    margin-bottom: 2rem;

    letter-spacing: .5rem;
    text-transform: uppercase;

    text-shadow: 0 15px 5px rgba(0, 0, 0, .1), 10px 20px 5px rgba(0, 0, 0, .05), -10px 20px 5px rgba(0, 0, 0, .05);
}

.home-banner .content .subtitle
{
    font-family: 'PT Serif', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 1.5rem;
    font-weight: 200;
    font-style: normal;
    line-height: 1;

    letter-spacing: 2px;

    text-shadow: 0 15px 5px rgba(0, 0, 0, .1), 10px 20px 5px rgba(0, 0, 0, .05), -10px 20px 5px rgba(0, 0, 0, .05);
}

.page.-quem-somos
{
    background-image: url(../img/bg-page-quem-somos.jpg);
    background-position: center center;
    background-size: cover;
}

.page.-quem-somos .page-wrapper
{
    background-color: rgba(35, 31, 32, .8);
}

@media (min-width: 576px)
{
    .page.-quem-somos .nav-social
    {
        display: flex;
    }
}

.page.-condominio .header-logo-container a
{
    display: flex;

    align-items: center;
}

.page.-condominio .header-logo-container .invisible
{
    font-family: 'PT Serif', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 2.5rem;
    font-weight: 200;
    font-style: normal;
    line-height: 1.15;

    position: static !important;

    display: none;
    visibility: visible !important;
    overflow: visible !important;
    clip: inherit !important;

    width: auto !important;
    height: auto !important;
    margin-left: 2rem;
}

.imovel .article-body .embed-responsive
{
    background-color: black;
    background-image: url(../img/logo-color.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 30%;
}

.masthead-imovel
{
    position: relative;
}

.masthead-imovel .logo-container
{
    margin-right: 3rem;
}

.masthead-imovel .logo-container .logo
{
    max-width: 200px;
}

@media (min-width: 576px)
{
    .masthead-imovel .logo-container .logo
    {
        max-width: 256px;
    }
}

.masthead-imovel .masthead-title
{
    z-index: 10;
    top: 0;
    left: 0;

    width: 100%;
    padding: 1rem 0;

    color: white;
    background-image: linear-gradient(to top, transparent, rgba(0, 0, 0, .95) 85%);
}

@media (min-width: 992px)
{
    .masthead-imovel .masthead-title
    {
        position: absolute;

        padding: 2rem 0 8rem 0;
    }
    .masthead-imovel .masthead-title .container-fluid
    {
        display: flex;

        align-items: center;
    }
    .masthead-imovel .masthead-title .imovel-info
    {
        display: flex;

        align-items: baseline;
    }
}

.masthead-imovel .masthead-title .title
{
    font-size: 1.5rem;

    margin-top: 3rem;
    margin-bottom: .5rem;

    white-space: nowrap;
    text-transform: uppercase;
}

@media (min-width: 992px)
{
    .masthead-imovel .masthead-title .title
    {
        margin-top: 0;
        margin-right: 1rem;
        margin-bottom: 0;
    }
}

@media (min-width: 576px)
{
    .masthead-imovel .masthead-title .list-info-imovel
    {
        display: flex;

        align-items: center;
    }
}

.masthead-imovel .masthead-title .list-info-imovel .info
{
    display: inline-block;

    white-space: nowrap;
}

.masthead-imovel .masthead-title .list-info-imovel .info + .info
{
    margin-left: 1rem;
}

.masthead-imovel .masthead-title .list-info-imovel .info.-preco
{
    font-size: 1.25rem;

    display: block;

    color: #b1856d;
}

@media (min-width: 576px)
{
    .masthead-imovel .masthead-title .list-info-imovel .info.-preco
    {
        font-size: 1rem;
    }
}

.masthead-imovel .masthead-title .list-info-imovel .info.-preco .val
{
    font-weight: 900;
}

.masthead-imovel .masthead-title .list-info-imovel .info.-area
{
    margin-left: 0;
}

@media (min-width: 576px)
{
    .masthead-imovel .masthead-title .list-info-imovel .info.-area
    {
        margin-left: 1rem;
    }
}

.masthead-imovel .masthead-title .list-info-imovel .info .img
{
    display: none;

    margin-right: .5rem;

    vertical-align: bottom;
}

@media (min-width: 576px)
{
    .masthead-imovel .masthead-title .list-info-imovel .info .img
    {
        display: inline-block;
    }
}

.masthead-imovel .slide-control.-prev
{
    right: auto;
    left: 0;
}

.masthead-imovel .slide-control.-next
{
    right: 0;
    left: auto;
}

.masthead-imovel .slick-dots
{
    position: absolute;
    bottom: 1rem;
}

.masthead-imovel .masthead-menu
{
    position: absolute;
    top: 0;
    right: 0;

    padding: 1.5rem;
}

@media (min-width: 576px)
{
    .masthead-imovel .masthead-menu
    {
        margin-top: 1.5rem;
        margin-right: 1.5rem;
        padding: 0;
    }
}

@media (min-width: 992px)
{
    .masthead-imovel .masthead-menu
    {
        margin-top: 2.5rem;
        margin-right: 2rem;
    }
}

#html .btn-menu
{
    padding: 0;

    color: white;
}

#html .btn-menu:hover
{
    color: #8d634b;
}

#html .btn-menu .fa
{
    font-size: 2rem;
    line-height: .8;
}

.masthead-imovel-thumbnails
{
    display: none;

    padding: .5rem 1.5rem;
}

@media (min-width: 576px)
{
    .masthead-imovel-thumbnails
    {
        display: block;
    }
}

.masthead-imovel-thumbnails .thumbnails-imovel
{
    width: 100%;
    margin: 0;

    flex-flow: row nowrap;
}

.masthead-imovel-thumbnails .item
{
    margin: .15rem;
}

@media (min-width: 992px)
{
    .masthead-imovel-thumbnails .item
    {
        margin: .35rem;
    }
}

.masthead-imovel-thumbnails .item.active img
{
    box-shadow: 0 0 0 2px #8d634b;
}

.nav-social.-imovel
{
    font-size: 13px;

    display: block;

    margin-top: 3rem;

    text-transform: uppercase;
}

@media (min-width: 576px)
{
    .nav-social.-imovel
    {
        display: flex;

        padding: 0 2rem;
        padding: 0;
    }
}

.nav-social.-imovel .nav-item + .nav-item
{
    margin-top: 1rem;
}

@media (min-width: 576px)
{
    .nav-social.-imovel .nav-item + .nav-item
    {
        margin-top: 0;
    }
}

.nav-social.-imovel .nav-link
{
    font-weight: 700;

    letter-spacing: 2px;

    color: white;
}

.nav-social.-imovel .nav-link.c-whatsapp
{
    color: inherit;
}

.nav-social.-imovel .nav-link.c-whatsapp i
{
    color: inherit;
}

.nav-social.-imovel .nav-link i
{
    font-size: 1.5rem;

    margin-right: .5rem;

    color: #8d634b;
}

#html .section-hero .form .form-group
{
    margin-bottom: 0;
}

@media (min-width: 576px)
{
    #html .section-hero .form .form-group.-flex
    {
        display: flex;

        align-items: center;
    }
    #html .section-hero .form .form-group.-flex label
    {
        margin-right: 1rem;
    }
}

#html .section-hero .form .input-mostra-valor
{
    font-weight: bold;

    width: 100%;
    min-width: 300px;
    margin-bottom: 6px;

    text-align: center;

    color: #8d634b;
    box-shadow: none;
}

#html .section-hero .form .noUi-target,
#html .section-hero .form .ui-slider-horizontal
{
    height: 4px;

    border: 0 none;
    background: transparent;
    background: rgba(255, 255, 255, .2);
    box-shadow: none;
}

#html .section-hero .form .noUi-connect,
#html .section-hero .form .ui-slider-horizontal .ui-widget-header
{
    background: rgba(255, 255, 255, .2);
    box-shadow: none;
}

#html .section-hero .form .noUi-handle,
#html .section-hero .form .ui-slider-horizontal .ui-slider-handle
{
    top: 50%;

    width: 1.25rem;
    height: 1.25rem;

    transform: translateY(-50%);

    border: 0 none;
    border-radius: 1.25rem;
    background: #8d634b;
    box-shadow: none;
}

#html .section-hero .form .noUi-handle::after,
#html .section-hero .form .noUi-handle::before
{
    display: none;
}

#html .video
{
    cursor: pointer;

    border-radius: 0;
    background-color: #8d634b;
}

#html .video:hover
{
    box-shadow: 0 0 0 2px #8d634b;
}

#html .video:hover .icone-play-video
{
    transform: translate(-50%, -50%) scale(1.25);
}

#html .video .icone-play-video
{
    font-size: 3rem;

    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;

    transition: all .4s cubic-bezier(.16, .56, .17, .999);
    transform: translate(-50%, -50%);
    text-align: center;
    pointer-events: none;

    color: #8d634b;
    color: white;
    background-color: transparent;
}

#html .video iframe
{
    z-index: 20;
}

#html .video .embed-responsive-item
{
    border-radius: 0;
}

#html .modal-open .modal
{
    background-color: rgba(0, 0, 0, .75);
}

#html .modal-imovel .modal-content
{
    border-radius: 0;
    background-color: #444;
}

#html .modal-imovel .close
{
    line-height: .6;

    position: absolute;
    top: 0;
    right: 0;

    margin: 1rem;

    color: #d7d7d7;
    text-shadow: none;
}

#html .modal-imovel .ajax-loader
{
    display: none;
}

#html .modal-imovel .wpcf7-validation-errors,
#html .modal-imovel .wpcf7-acceptance-missing
{
    border-color: #8d634b;
}

#html .link-whatsapp:hover
{
    color: #25d366 !important;
}

/* Extra small devices (portrait phones, less than 576px)
No media query since this is the default in Bootstrap */
/* Small devices (landscape phones, 576px and up) */
/* Medium devices (tablets, 768px and up) */
/* Large devices (desktops, 992px and up) */
/* Extra large devices (large desktops, 1200px and up) */
/* Extra small devices (portrait phones, less than 576px) */
/* Small devices (landscape phones, less than 768px) */
/* Medium devices (tablets, less than 992px) */
/* Large devices (desktops, less than 1200px) */
/* Extra small devices (portrait phones, less than 576px) */
/* Small devices (landscape phones, 576px and up) */
/* Medium devices (tablets, 768px and up) */
/* Large devices (desktops, 992px and up) */
/* Extra large devices (large desktops, 1200px and up) */
